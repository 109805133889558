import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";
import { Action } from "../actions/filters";
import { Epic } from "redux-observable";
import { isOfType } from "typesafe-actions";
import { of, forkJoin } from "rxjs";
import { ajax } from "rxjs/ajax";
import { mergeMap, map, catchError, filter } from "rxjs/operators";
import {
  normalizeFlatList,
  normalizeTreeData,
  normalizeTreeDataAll
} from "../utils/norm";
import domain from "../utils/domain";
import { State, CategoryEntities } from "../interfaces";

const fetchMarkets = () => (action: any, state: State) => {
  const { auth, language } = state;
  const accessToken = auth.accessToken;

  return ajax({
    crossDomain: true,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_markets?_format=json&lang=${language.selectedLanguage?.id}`
  });
};

const getSelectedMarkets = (state: State) => {
  const { filters } = state;
  const markets = filters.markets.selectedMarkets;
  const _markets = markets.length > 0 ? markets : "";
  // console.log("markets ", _markets);
  return _markets;
};

const fetchTypes = () => (action: any, state: State) => {
  const { auth, language } = state;
  const accessToken = auth.accessToken;
  return ajax({
    crossDomain: true,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_types?_format=json&lang=${
      language.selectedLanguage?.id
    }&market=${getSelectedMarkets(state)}`
  });
};

const fetchCategories = () => (action: any, state: State) => {
  /*
      categorie
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */
  const { auth, language } = state;
  const accessToken = auth.accessToken;

  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_categories?_format=json&lang=${
      language.selectedLanguage?.id
    }&market=${getSelectedMarkets(state)}`
  });
};

const fetchTags = () => (action: any, state: State) => {
  /*
      tags
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */

  const { auth, language } = state;
  const accessToken = auth.accessToken;
  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_tags?_format=json&lang=${
      language.selectedLanguage?.id
    }&market=${getSelectedMarkets(state)}&all=${true}`
  });
};

const fetchProperties = () => (action: any, state: State) => {
  /*
      tags
      Parameters:
      types
      market
  
      depth - default 0 (the whole branch)
    */

  const { auth, language } = state;
  const accessToken = auth.accessToken;
  return ajax({
    crossDomain: true,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
    url: `${domain}api/item/item_properties?_format=json&lang=${
      language.selectedLanguage?.id
    }&market=${getSelectedMarkets(state)}`
  });
};

export const getFiltersEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(
      isOfType([
        actionTypes.GET_FILTERS,
        actionTypes.SET_MARKET,
        actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST
      ])
    ),
    mergeMap((action) => {
      // uso forkJoin per chiamare più servizi in sequenza
      return forkJoin(
        fetchMarkets()(action, state$.value),
        fetchTypes()(action, state$.value),
        fetchCategories()(action, state$.value),
        fetchTags()(action, state$.value),
        fetchProperties()(action, state$.value)
      ).pipe(
        map((results) => {
          // forkJoin ritorna un array con i risultati delle chiamate precedenti
          const [markets, typologies, categories, tags, properties] = results;
          const _typologies = normalizeFlatList(typologies.response.item_type);
          const _markets = normalizeFlatList(markets.response.market);
          const _categories: CategoryEntities = normalizeTreeData(
            categories.response
          );
          const allById = normalizeTreeDataAll(categories.response);
          //aggiungo anche la struttura flat
          _categories.allById = allById.byId ?? {};
          const _tags = normalizeFlatList(tags.response);
          const _properties = normalizeTreeData(properties.response);
          // console.log("markets ", _markets);
          return actions.getFiltersSuccess(
            _markets,
            _typologies,
            _categories,
            _tags,
            _properties
          );
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.getFiltersError(message, error.status));
        })
      );
    })
  );
