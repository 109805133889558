import moment from "moment";
import { Dictionary } from "../interfaces";
import { CategoryAll } from "../reducers/categories";
import { SHA1, enc } from "crypto-js";

export const detectIE11 = () => {
  var ua = window.navigator.userAgent;

  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11, return version number
    return true;
  } else {
    return false;
  }
};

export const disableTreeNode = (node: Dictionary<any>, ids: string[]) => {
  const { key, children } = node;
  // controllo se il nodo è presente nella lista dei filtri attivi
  const isNodeDisabled = ids.indexOf(key) === -1;
  // se il nodo ha figli cerco tra i figli
  if (children !== undefined && children.length > 0) {
    let foundAtLeastOneChildredEnabled = false;
    const _children = children.map((_node: Dictionary<any>) => {
      const treeNode = disableTreeNode(_node, ids);
      // se trovo almeno un children abilitato non devo disabilitare il padre
      if (!foundAtLeastOneChildredEnabled)
        foundAtLeastOneChildredEnabled = ids.indexOf(_node.key) !== -1;
      return treeNode;
    });
    const _node = {
      ...node,
      children: _children,
      disabled: foundAtLeastOneChildredEnabled ? false : isNodeDisabled
    };
    return _node;
  } else {
    const newNode = {
      ...node,
      disabled: isNodeDisabled
    };
    return newNode;
  }
};

export const disableMainTreeNode = (node: Dictionary<any>, ids: string[]) => {
  const { key, children } = node;
  // controllo se il nodo è presente nella lista dei filtri attivi
  const isNodeDisabled = ids.indexOf(key) === -1;
  // se il nodo ha figli cerco tra i figli
  if (children !== undefined && children.length > 0) {
    let foundAtLeastOneChildredEnabled = false;
    const _children = children.map((_node: Dictionary<any>) => {
      const treeNode = disableTreeNode(_node, ids);
      // se trovo almeno un children abilitato non devo disabilitare il padre
      if (!foundAtLeastOneChildredEnabled)
        foundAtLeastOneChildredEnabled = !treeNode.disabled;
      return treeNode;
    });
    const _node = {
      ...node,
      children: _children,
      isDisabled: foundAtLeastOneChildredEnabled ? false : isNodeDisabled
    };
    return _node;
  } else {
    const newNode = {
      ...node,
      isDisabled: isNodeDisabled
    };
    return newNode;
  }
};

export const disableFlatNode = (node: Dictionary<any>, ids: string[]) => {
  const { id } = node;
  return {
    ...node,
    disabled: ids.indexOf(id) === -1
  };
};

export const getChildrenNod = (treeNode: Dictionary<any>) => {
  const { node, children } = treeNode;
  let _keys: any[] = [];
  if (children !== undefined) {
    children.forEach((element: any) => {
      _keys = [..._keys, ...getChildrenNod(element)];
    });
  }

  let keys = node ? [node.key, ..._keys] : _keys;
  return keys;
};

export const getChildrenNode = (
  ids: string[],
  categoriesById: Dictionary<CategoryAll>
) => {
  let keys: string[] = [];
  ids.forEach((id) => {
    if (categoriesById) {
      const node = categoriesById[id];
      let key = node.key;
      if (node.children !== undefined) {
        keys = [...getChildrenNode(node.children, categoriesById), ...keys];
      }
      keys = [key, ...keys];
    }
  });

  return keys;
};

export const formatDate = (date: string): string => {
  const _date = moment(date);
  return _date.format("L");
};

export const getLanguageIcon = (id: string): string => {
  // const code = id.substr(3, 2);
  const code = id === "en" ? "GB" : id.toUpperCase();
  return code;
};

export const fileScr = async (file: Blob): Promise<string> => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error("Problem parsing file"));
    };

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.readAsDataURL(file);
  });
};

export const encrypt = (message: string) => {
  const encrypted = SHA1(message).toString(enc.Hex);
  return encrypted;
};
