import { EditOutlined } from "@ant-design/icons";
import { Button, Drawer, Layout } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMedia } from "react-use";

import * as actions from "../../actions";
import EditModal from "../../components/editmodal";
import MasonryList from "../../components/masonrylist";
import SearchPanel from "../../components/searchpanel";
import ShareModal from "../../components/share/modal";

import { FiltersState } from "../../reducers/filters";
import { ItemsState } from "../../reducers/items";
import { LanguageState } from "../../reducers/language";
import { ShareState } from "../../reducers/share";

// import { useWhyDidYouUpdate } from "../../hooks/useWhyDidYouUpdate";
import { logTMDataLayer } from "../../hooks/useLogTMDataLayer";
import { State } from "../../interfaces";
import { AuthState } from "../../reducers/auth";
import { encrypt } from "../../utils";

const { Content, Header } = Layout;

const sidebar = (
  <div className="search-panel">
    <SearchPanel />
  </div>
);

const Home = (props: Object) => {
  // useWhyDidYouUpdate("Home", props);
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { user } = auth;

  console.log("user", user);

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { isLoading: isLoadingFilters } = filters;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  useEffect(() => {
    logTMDataLayer({
      dataLayer: {
        event: "init",
        userId: user?.email ? encrypt(user?.email as string) : undefined,
        user_type: user?.role?.[0],
        pageType: "home",
        language: language.selectedLanguage?.id,
        country: undefined,
        loginStatus: !!user ? "Logged" : "Guest"
      },
      dataLayerName: "dataLayer"
    });
  }, [user, language]);

  const { labels } = language;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const {
    entities,
    total,
    editMode,
    showModal: showEditModal,
    userCanEdit
  } = items;

  const share: ShareState = useSelector(
    (state: State) => state.share,
    shallowEqual
  );

  const { showModal: showShareModal } = share;

  const dispatch = useDispatch();

  const onToggleEditMode = useCallback(
    () => dispatch(actions.toggleEditMode()),
    [dispatch]
  );

  const [open, setOpen] = useState(false);
  const isSmall = useMedia("(max-width: 768px)");

  const onClose = () => {
    setOpen(false);
  };

  const onToggleMenu = () => {
    setOpen((open) => !open);
  };

  return (
    <>
      <Layout className="main-layout">
        <Content className={"gallery " + (isSmall ? "gallery-mobile" : "")}>
          {labels && (
            <Header
              className={"grid-header " + (isSmall ? "grid-header-mobile" : "")}
            >
              {isSmall && ( // mobile of search panel
                <Button className="menu-btn" onClick={onToggleMenu}>
                  <FaFilter />
                </Button>
              )}
              <span style={{ flex: 1 }}>
                {entities.all.length}/{total} {labels.itemsFound}
              </span>
              {userCanEdit && (
                <Button onClick={onToggleEditMode}>
                  <EditOutlined />{" "}
                  {editMode ? `${labels.cancelEdit}` : `${labels.edit}`}
                </Button>
              )}
            </Header>
          )}
          {labels && !isLoadingFilters && <MasonryList labels={labels} />}
          {showShareModal && <ShareModal />}
          {showEditModal && <EditModal />}
        </Content>
      </Layout>
      <Drawer
        className={isSmall ? "mobile" : "desktop"}
        style={{ overflow: "auto" }}
        getContainer=".main-layout"
        mask={isSmall}
        closable={false}
        placement="left"
        visible={open || !isSmall}
        width={250}
        maskClosable={isSmall}
        onClose={onClose}
      >
        {sidebar}
      </Drawer>
    </>
  );
};

export default Home;
