import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  LinkOutlined,
  QuestionCircleOutlined,
  ShareAltOutlined
} from "@ant-design/icons";
import { Layout, Menu, Row, Popover, Button } from "antd";
import { useMedia } from "react-use";
import {
  FaFacebookSquare,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaLinkedin
} from "react-icons/fa";
import { MdMenu } from "react-icons/md";
import * as actions from "../../actions";

import { getLanguageIcon } from "../../utils";
import { AuthState } from "../../reducers/auth";
import { LanguageState } from "../../reducers/language";
import { State } from "../../interfaces";
import { ClickParam } from "antd/lib/menu";
import {
  LINKEDIN_URL,
  INSTAGRAM_URL,
  YOUTUBE_URL,
  TWITTER_URL,
  FACEBOOK_URL,
  PRESS_KIT_URL,
  BROCHURE_BANK_URL,
  CASE_SITE_URL
} from "../../constants/actionTypes";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Header } = Layout;
const logo = require("../../images/logo.png");

const Nav = () => {
  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { entities, selectedLanguage, labels } = language;
  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { user, isFetching } = auth;

  const dispatch = useDispatch();
  const onLogout = useCallback(() => dispatch(actions.logout()), [dispatch]);
  const onSelectLanguage = useCallback(
    (language) => dispatch(actions.selectLanguage(language)),
    [dispatch]
  );
  const onHomeClicked = useCallback(() => dispatch(actions.getFilters()), [
    dispatch
  ]);

  const isSmall = useMedia("(max-width: 768px)");

  const handleClick = (param: ClickParam) => {
    if (param.key.includes("flag")) {
      const code = param.key.substr(5, 5);
      const language = entities.byId[code];
      onSelectLanguage(language);
    }
  };

  const openHelp = () => {
    let isManager = false;
    let role = "";
    isManager = user?.role.includes("manager") || false;
    role = isManager ? "_manager" : "";

    if (selectedLanguage) {
      window.open(`help/help_${selectedLanguage.id}${role}.pdf`);
    }
  };

  const languages = entities.all.map((_id: string) => {
    const { id, name } = entities.byId[_id];
    return (
      <Menu.Item key={`flag:${id}`}>
        <div className="flag-wrapper">
          <img
            className="flag"
            src={`flags/${getLanguageIcon(id)}.png`}
            alt={name}
          />
          <span className="flag-name">{name}</span>
        </div>
      </Menu.Item>
    );
  });

  const links = [
    <Menu.Item key="link:1">
      <a href={CASE_SITE_URL} target="_blank" rel="noopener noreferrer">
        Web Site
      </a>
    </Menu.Item>,
    <Menu.Item key="link:2">
      <a href={BROCHURE_BANK_URL} target="_blank" rel="noopener noreferrer">
        Brochure Bank
      </a>
    </Menu.Item>,
    <Menu.Item key="link:3">
      <a href={PRESS_KIT_URL} target="_blank" rel="noopener noreferrer">
        Press Kit
      </a>
    </Menu.Item>
  ];

  const social = [
    <Menu.Item key="setting:1">
      <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
        <FaFacebookSquare className="social-icon" />
        Facebook
      </a>
    </Menu.Item>,
    <Menu.Item key="setting:2">
      <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
        <FaTwitter className="social-icon" />
        Twitter
      </a>
    </Menu.Item>,
    <Menu.Item key="setting:4">
      <a href={YOUTUBE_URL} target="_blank" rel="noopener noreferrer">
        <FaYoutube className="social-icon" />
        Youtube
      </a>
    </Menu.Item>,
    <Menu.Item key="setting:5">
      <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
        <FaInstagram className="social-icon" />
        Instagram
      </a>
    </Menu.Item>,
    <Menu.Item key="setting:6">
      <a href={LINKEDIN_URL} target="_blank" rel="noopener noreferrer">
        <FaLinkedin className="social-icon" />
        Linkedin
      </a>
    </Menu.Item>
  ];

  const welcome = labels ? labels.welcome : null;

  return (
    <Header className="main-nav">
      <Row justify="space-between" align="middle">
        <Link className="logo-link" to="/welcome" onClick={onHomeClicked}>
          <img src={logo} alt="" className="logo" />
        </Link>
        <h1>Tools</h1>
        <div className="navbar">
          {user &&
            (isSmall ? (
              <p />
            ) : (
              <p className="welcome">
                {welcome} {user.firstName}
              </p>
            ))}
          {user && !user.portal && (
            <Button
              className="logout-button"
              onClick={() => onLogout()}
              loading={isFetching}
            >
              Log out
            </Button>
          )}
          {isSmall ? (
            // menu mobile
            <Popover
              overlayClassName="popover-menu-mobile"
              placement="bottomRight"
              // getPopupContainer={triggerNode => triggerNode.parentNode}
              content={
                <Menu className="menu-mobile" onClick={handleClick}>
                  {selectedLanguage && (
                    <MenuItemGroup key="l1" title="Languages">
                      {languages.map((item) => item)}
                    </MenuItemGroup>
                  )}
                  <MenuItemGroup key="g1" title="Links">
                    {links.map((item) => item)}
                  </MenuItemGroup>
                  <MenuItemGroup key="g2" title="Social">
                    {social.map((item) => item)}
                  </MenuItemGroup>
                  <Menu.Item className="help-mobile" onClick={openHelp}>
                    Help
                  </Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button className="menu-btn">
                <MdMenu />
              </Button>
            </Popover>
          ) : (
            // menu desktop
            <Menu
              className="menu"
              theme="light"
              mode="horizontal"
              onClick={handleClick}
            >
              {selectedLanguage && (
                <SubMenu
                  title={
                    <div className="flag-wrapper">
                      <img
                        className="flag"
                        src={`/flags/${getLanguageIcon(
                          selectedLanguage.id
                        )}.png`}
                        alt={selectedLanguage.name}
                      />
                    </div>
                  }
                >
                  {languages.map((item) => item)}
                </SubMenu>
              )}
              <SubMenu
                title={
                  <span>
                    <LinkOutlined />
                    Links
                  </span>
                }
              >
                {links.map((item) => item)}
              </SubMenu>
              <SubMenu
                title={
                  <span>
                    <ShareAltOutlined />
                    Social Media
                  </span>
                }
              >
                {social.map((item) => item)}
              </SubMenu>
              <SubMenu
                title={
                  <span className="help" onClick={openHelp}>
                    <QuestionCircleOutlined />
                  </span>
                }
              />
            </Menu>
          )}
        </div>
      </Row>
    </Header>
  );
};

export default Nav;
