import { CloseCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { Card, Popconfirm, Row, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { Dictionary } from "../../interfaces";
import { ItemFile } from "../../reducers/items";
import { formatDate } from "../../utils";
import DynamicIcon from "../DynamicIcon";
import FileLoader from "../FileLoader";
import DownloadBtn from "../downloadbtn";
import ItemTag from "../itemtag";
import ShareBtn from "../share/shareBtn";

// import { useWhyDidYouUpdate } from "../../hooks/useWhyDidYouUpdate";

interface Item {
  id: string;
  thumbUrl: string;
  name: string;
  icon: string;
  publishDate: string;
  can_edit: boolean;
  tags: string[];
  markets: string[];
  files: Array<ItemFile>;
}

interface Props {
  item: Item;
  noLabel: string;
  yesLabel: string;
  downloadLabel: string;
  unpublishWarningLabel: string;
  addremovetags: string;
  tagById: Dictionary<any>;
  marketById: Dictionary<any>;
  userCanShare: boolean;
  editMode: boolean;
  onItemSelect: Function;
  onTagClicked: Function;
  onRemoveItem: Function;
  onShare: Function;
  onOpenEditModal: Function;
}

interface ItemProps {
  id: string;
  unpublishWarningLabel: string;
  noLabel: string;
  yesLabel: string;
  can_edit: boolean;
  editMode: boolean;
  removeItem: Function;
}

const UpublishBtn = ({
  id,
  noLabel,
  yesLabel,
  unpublishWarningLabel,
  editMode,
  can_edit,
  removeItem
}: ItemProps) => {
  const _onClick = () => {
    removeItem(id);
    console.log("unpublish id ", id);
  };

  return editMode && can_edit ? (
    <Popconfirm
      placement="bottomRight"
      title={unpublishWarningLabel}
      onConfirm={_onClick}
      okText={yesLabel}
      cancelText={noLabel}
    >
      <CloseCircleOutlined className="unpublish" />
    </Popconfirm>
  ) : null;
};

const ThumbItem: React.FC<Props> = (props) => {
  const {
    item,
    tagById,
    marketById,
    downloadLabel,
    unpublishWarningLabel,
    addremovetags,
    noLabel,
    yesLabel,
    editMode,
    userCanShare,
    onItemSelect,
    onShare,
    onTagClicked,
    onRemoveItem,
    onOpenEditModal
  } = props;

  const {
    id,
    name,
    thumbUrl,
    icon,
    publishDate,
    tags,
    markets,
    files,
    can_edit
  } = item;

  const selectItem = () => {
    onItemSelect(item);
  };

  const handleOnShare = () => {
    onShare(item);
  };

  const handleOnOpenEditModal = () => {
    onOpenEditModal(item);
  };
  // console.log("ThumbItem");
  return (
    <Card
      className="thumb"
      title={
        <div className="card-title">
          <DynamicIcon type={icon} /> <p>{name}</p>
        </div>
      }
      style={{ display: "block" }}
      bodyStyle={{ padding: 0 }}
      extra={
        <UpublishBtn
          id={id}
          can_edit={can_edit}
          editMode={editMode}
          removeItem={onRemoveItem}
          unpublishWarningLabel={unpublishWarningLabel}
          noLabel={noLabel}
          yesLabel={yesLabel}
        />
      }
    >
      <Link to={`/details/${id}`}>
        <div className="custom-image" onClick={selectItem}>
          {thumbUrl && thumbUrl !== "" && (
            <FileLoader fileName={name} fileUrl={thumbUrl} type="tag" />
          )}
          <Row className="img-overlay" align="middle" justify="center">
            <EyeOutlined className="icon" />
          </Row>
        </div>
      </Link>
      <div className="custom-card">
        <div className="details-bar">
          <h4>{formatDate(publishDate)}</h4>
          <div>
            {marketById &&
              markets.map((market) => {
                return marketById[market] !== undefined ? (
                  <Tag
                    className="item-tag"
                    key={market}
                    id={market}
                    color="#e68f1a"
                  >
                    {marketById[market].name}
                  </Tag>
                ) : (
                  console.log("market non presente nella lista ", market)
                );
              })}
          </div>
        </div>
        {editMode && (
          <Tag
            className="item-tag"
            color="#000"
            onClick={handleOnOpenEditModal}
          >
            {addremovetags}
          </Tag>
        )}
        {tagById &&
          tags.map((tag) => {
            return tagById[tag] !== undefined ? (
              <ItemTag
                key={tag}
                id={tag}
                itemId={id}
                name={tagById[tag].name}
                tagClicked={onTagClicked}
              />
            ) : (
              console.log("tag non presente nella lista ", tag)
            );
          })}
        <div className="btns-wrapper">
          <DownloadBtn
            direction="topCenter"
            clsName="btn-white"
            files={files}
            itemID={id}
            label={downloadLabel}
          />
          {userCanShare && (
            <ShareBtn
              clsName="btn-white"
              files={files}
              itemID={id}
              onShare={handleOnShare}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default React.memo<Props>(ThumbItem);
