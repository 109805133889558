import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { Action as MarketsAction } from "../actions/markets";
import { Action as FilterssAction } from "../actions/filters";
import { Action as LanguagesesAction } from "../actions/language";
import { Entities, Market } from "../interfaces";

export type Action = MarketsAction | LanguagesesAction | FilterssAction;

export type MarketsState = ReturnType<typeof markets>;

const entities = (
  state: Entities<Market> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
      const { all, byId } = action.payload.markets;
      return {
        all,
        byId
      };
    case actionTypes.SELECT_LANGUAGE:
      return {
        all: [],
        byId: {}
      };
    default:
      return state;
  }
};

const selectedMarkets = (state: string[] = [], action: Action) => {
  switch (action.type) {
    // case actionTypes.GET_FILTERS_SUCCESS:
    //   const { all } = action.payload.markets;
    //   return [all[0]];

    case actionTypes.SET_MARKET:
      const { typeIds } = action.payload;
      return typeIds;

    case actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST:
      const { typeId } = action.payload;
      let list = [...state];
      // controllo se già esiste
      if (state.indexOf(typeId) > -1) {
        // se si lo rimuovo
        list = state.filter(id => id !== typeId);
      } else {
        // altrimenti lo aggiungo
        list.push(typeId);
      }
      return list;

    case actionTypes.SELECT_LANGUAGE:
      return [];
    default:
      return state;
  }
};

const markets = combineReducers({
  entities,
  selectedMarkets
});

export default markets;
