import React, { useCallback, useEffect, useState } from "react";
import { useInfiniteScroll } from "react-infinite-scroll-hook";
import Masonry from "react-masonry-css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions";
import { Loader } from "../loader";
import ThumbItem from "../thumbitem";

import { FiltersState } from "../../reducers/filters";
import { Item, ItemsState } from "../../reducers/items";
// import { useWhyDidYouUpdate } from "../../hooks/useWhyDidYouUpdate";
import { logTMDataLayer } from "../../hooks/useLogTMDataLayer";
import { Dictionary, State } from "../../interfaces";

const breakpointColumnsObj = {
  default: 6,
  "1900": 5,
  "1600": 4,
  "1200": 3,
  "992": 2,
  "767": 2,
  "576": 1
};
interface Props {
  labels: Dictionary<string>;
}
const Masonrylist: React.FC<Props> = (props) => {
  // useWhyDidYouUpdate("Masonrylist", props);
  const { labels } = props;
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { typologies, tags, markets } = filters;
  const { byId: typologyById } = typologies.entities;
  const { byId: tagById } = tags.entities;
  const { byId: marketById } = markets.entities;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const {
    entities,
    page,
    total,
    isLoading,
    hasError,
    selectedItem,
    itemsPerPage,
    editMode,
    userCanShare
  } = items;

  const { all, byId } = entities;

  const mapItems = useCallback(() => {
    return all.map((itemId: string, index: number) => {
      const item = byId[itemId];
      const cat = typologyById[item?.typeID?.[0]]?.name;
      const tag =
        !!item?.tags?.length && tagById?.[item?.tags?.[0]]
          ? tagById[item?.tags?.[0]]?.name
          : undefined;
      return {
        item_name: undefined,
        item_id: item?.name,
        price: 1,
        item_category: tag,
        item_category2: cat
      };
    });
  }, [all, byId, tagById, typologyById]);

  useEffect(() => {
    if (all.length > 0 && !isLoading) {
      logTMDataLayer({
        dataLayer: {
          event: "view_item_list",
          ecommerce: {
            items: mapItems()
          }
        },
        dataLayerName: "dataLayer"
      });
    }
  }, [all, isLoading, mapItems]);

  const dispatch = useDispatch();

  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    setModalStatus(selectedItem !== null);
  }, [selectedItem]);

  useEffect(() => {
    // controllo che ci siano ancora item da richiedere e non ci siano errori
    setHasNextPage((page + 1) * itemsPerPage < total && !hasError);
  }, [hasError, itemsPerPage, page, total]);

  const setModalStatus = (open: boolean) => {
    if (document.body) {
      if (open) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    }
  };

  const onGetNextPageForItems = useCallback(
    (page) => dispatch(actions.getNextPageForItems(page)),
    [dispatch]
  );

  const handleOnSelectItem = useCallback(
    (id) => {
      dispatch(actions.selectItem(id));
    },
    [dispatch]
  );

  const handleOnShare = useCallback(
    (item: Item) => {
      dispatch(actions.showShareModal(true, item));
    },
    [dispatch]
  );

  const handleOnOpenEditModal = useCallback(
    (item: Item) => {
      dispatch(actions.showEditModal(true, item));
    },
    [dispatch]
  );

  const handleOnTagClicked = useCallback(
    (tag: string) => dispatch(actions.setTags([tag])),
    [dispatch]
  );

  const onRemoveItem = useCallback(
    (id) => dispatch(actions.getNewSessionToken(actions.unpublishItem(id))),
    [dispatch]
  );

  // const onRemoveTag = useCallback(
  //   (id, itemId) =>
  //     dispatch(actions.getNewSessionToken(actions.editTagsForItem(id, itemId))),
  //   [dispatch]
  // );

  const onPaginatedSearch = () => {
    // console.log("onPaginatedSearch ");
    // controllo che ci siano ancora item da richiedere
    if (hasNextPage) {
      onGetNextPageForItems(page + 1);
    }
  };

  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading: isLoading,
    // This value is set to "true" for this demo only. You will need to
    // get this value from the API when you request your items.
    hasNextPage,
    onLoadMore: onPaginatedSearch,
    scrollContainer: "window"
  });

  // console.log("masonry ");

  return (
    <div className="masonry-wrapper" ref={infiniteRef}>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry"
        columnClassName="masonry-column"
      >
        {all.map((itemId: string, index: number) => {
          const item = byId[itemId];
          const icon = typologyById[item.typeID[0]].icon;
          item.icon = icon;

          return (
            <ThumbItem
              key={itemId}
              item={item}
              editMode={editMode}
              userCanShare={userCanShare}
              tagById={tagById}
              marketById={marketById}
              onItemSelect={handleOnSelectItem}
              onShare={handleOnShare}
              onOpenEditModal={handleOnOpenEditModal}
              onTagClicked={handleOnTagClicked}
              onRemoveItem={onRemoveItem}
              downloadLabel={labels.download}
              unpublishWarningLabel={labels.unpublishWarning}
              addremovetags={labels.addremovetags}
              noLabel={labels.no}
              yesLabel={labels.yes}
            />
          );
        })}
      </Masonry>
      <Loader isLoading={isLoading} />
      {!isLoading && !hasNextPage && (
        <p style={{ textAlign: "center", marginTop: 20 }}>
          {labels.noMoreItemLabel}
        </p>
      )}
    </div>
  );
};

export default Masonrylist;
