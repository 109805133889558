import * as actionTypes from "../constants/actionTypes";

export interface SetTypeAction {
  type: typeof actionTypes.SET_TYPOLOGY;
  payload: { typeIds: string[] };
}

export interface ToggleTypeAction {
  type: typeof actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST;
  payload: { typeId: string };
}

export type Action = SetTypeAction | ToggleTypeAction;

export function setTypology(typeIds: string[]): Action {
  return {
    type: actionTypes.SET_TYPOLOGY,
    payload: {
      typeIds
    }
  };
}

export function toggleTypologyOnSelectedList(typeId: string): Action {
  return {
    type: actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST,
    payload: {
      typeId
    }
  };
}
