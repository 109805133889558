import * as actionTypes from "../constants/actionTypes";
import * as actions from "../actions";
import { Action } from "../actions/share";
import { Epic } from "redux-observable";
import { isOfType } from "typesafe-actions";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { switchMap, map, catchError, filter } from "rxjs/operators";
import domain from "../utils/domain";
import { State } from "../interfaces";

export const getSharedLinksEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_SHARED_LINKS)),
    switchMap((action) => {
      const { nid, fids, exp } = action.payload;

      const state: State = state$.value;
      const { auth, language } = state;
      const { accessToken, sessionToken } = auth;

      return ajax({
        body: {
          lang: language.selectedLanguage?.id,
          nid,
          fids,
          exp
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/share?_format=json`,
        crossDomain: true,
        method: "POST"
      }).pipe(
        map(({ response }) => {
          const links = response.links;
          return actions.getSharedLinksSuccess(links);
        }),
        catchError((error) => {
          console.log("share error ", error);
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.getSharedLinksError(message, error.status));
        })
      );
    })
  );
