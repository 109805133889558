import React from "react";
import PropTypes from "prop-types";
import { TreeSelect } from "antd";
import { useMedia } from "react-use";
import { Category } from "../../../reducers/categories";

const TreeNode = TreeSelect.TreeNode;

interface Props {
  placeholder: string;
  treeData: Category[];
  selectedNodes: string[];
  selectAllChildren: boolean;
  onSelectNode: ((value: string[], label: any, extra: any) => void) | undefined;
}

const CategoryTreeSelect = ({
  placeholder,
  onSelectNode,
  selectedNodes,
  selectAllChildren,
  treeData
}: Props) => {
  const isSmall = useMedia("(max-width: 768px)");

  const filterCategories = (inputValue: string, treeNode: any) => {
    const index = treeNode.props.title
      .toLowerCase()
      .indexOf(inputValue.toLowerCase());
    let containsString = index !== -1;
    return containsString;
  };

  const renderTreeNodes = (data: Category[]) => {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.label} dataRef={item} {...item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode title={item.label} dataRef={item} {...item} />;
    });
  };
  console.log("CategoryTreeSelect");
  return (
    <TreeSelect
      className="search-panel-element"
      multiple={true}
      treeCheckable={true}
      showCheckedStrategy="SHOW_PARENT"
      placeholder={placeholder}
      filterTreeNode={filterCategories}
      dropdownStyle={{ maxHeight: isSmall ? 300 : 400 }}
      allowClear={true}
      treeCheckStrictly={!selectAllChildren}
      value={selectedNodes}
      onChange={onSelectNode}
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
    >
      {renderTreeNodes(treeData)}
    </TreeSelect>
  );
};

export default CategoryTreeSelect;

CategoryTreeSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  treeData: PropTypes.array.isRequired,
  selectedNodes: PropTypes.array.isRequired,
  selectAllChildren: PropTypes.bool.isRequired,
  onSelectNode: PropTypes.func.isRequired
};
