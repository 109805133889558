import React from "react";
import { ShareAltOutlined } from '@ant-design/icons';
import { Button } from "antd";
import { ItemFile } from "../../../reducers/items";

type Direction =
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight"
  | undefined;

type Props = {
  itemID: string;
  files: Array<ItemFile>;
  direction?: Direction;
  label?: string;
  clsName?: string;
  onShare: Function;
};

const ShareBtn: React.FC<Props> = props => {
  const { label, clsName, onShare, itemID } = props;
  // const [placement, setPlacement]=useState(direction)

  // constructor(props: Props) {
  //   super(props);
  //   this.state = {
  //     placement: props.direction,
  //     clsName: props.clsName
  //   };
  // }

  const handleOnShare = (e: any) => {
    onShare(itemID);
  };

  return (
    <Button className={`share-btn ${clsName}`} onClick={handleOnShare}>
      {label}
      <ShareAltOutlined style={label === undefined ? { marginLeft: 0 } : {}} />
    </Button>
  );
};

ShareBtn.defaultProps = {
  direction: "topCenter" as Direction,
  label: "",
  clsName: "btn-grey"
};

export default ShareBtn;
