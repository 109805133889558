import * as actionTypes from "../constants/actionTypes";

export interface SetMarketAction {
  type: typeof actionTypes.SET_MARKET;
  payload: { typeIds: string[] };
}
export interface ToggleMarketAction {
  type: typeof actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST;
  payload: { typeId: string };
}
export type Action = SetMarketAction | ToggleMarketAction;

export function setMarket(typeIds: string[]): Action {
  return {
    type: actionTypes.SET_MARKET,
    payload: {
      typeIds
    }
  };
}

export function toggleMarketOnSelectedList(typeId: string): Action {
  return {
    type: actionTypes.TOGGLE_MARKET_ON_SELECTED_LIST,
    payload: {
      typeId
    }
  };
}
