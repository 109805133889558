import { useEffect } from "react";
import TagManager, { DataLayerArgs } from "react-gtm-module";

export const logTMDataLayer = (args: DataLayerArgs) => {
  TagManager.dataLayer(args);
};

export const useLogTMDataLayer = (args: DataLayerArgs) => {
  useEffect(() => {
    logTMDataLayer(args);
  }, [args]);

  return true;
};
