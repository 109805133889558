import { of } from "rxjs";
import * as actions from "../actions";
import { combineEpics } from "redux-observable";
import {
  fetchItemsEpic,
  fetchNextPageEpic,
  setFilterForSearchEpic,
  resetPageForItemsEpic,
  fetchItemByIdEpic,
  removeItemByIdEpic,
  editTagsForItemEpic,
  fetchItemsAfterEditEpic
} from "./items";

import {
  sessionTokenEpic,
  autoLoginEpic,
  loginEpic,
  loginWithTokenEpic,
  loginSuccessEpic,
  getUserEpic,
  refreshTokenEpic,
  logoutEpic,
  recoverPasswordEpic,
  unauthorizedUserEpic,
  newSessionTokenEpic
} from "./auth";

import { getFiltersEpic } from "./filters";
import {
  getLanguagesEpic,
  setBrowserLanguageEpic,
  setLanguageEpic
} from "./language";
import { getSharedLinksEpic } from "./share";

export function initEpic() {
  // get browser language
  let locale =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    "en-US";

  // prendo i primi 2 caratteri della lingua
  const language = locale.substr(0, 2);

  return of(
    actions.setBrowserLanguage(language),
    actions.getLanguages(),
    actions.getSessionToken()
  );
}

export const rootEpic = combineEpics(
  initEpic,
  getLanguagesEpic,
  setBrowserLanguageEpic,
  setLanguageEpic,
  sessionTokenEpic,
  newSessionTokenEpic,
  autoLoginEpic,
  loginEpic,
  loginWithTokenEpic,
  loginSuccessEpic,
  getUserEpic,
  refreshTokenEpic,
  logoutEpic,
  recoverPasswordEpic,
  unauthorizedUserEpic,
  getFiltersEpic,
  fetchItemsEpic,
  fetchNextPageEpic,
  setFilterForSearchEpic,
  resetPageForItemsEpic,
  fetchItemByIdEpic,
  removeItemByIdEpic,
  editTagsForItemEpic,
  fetchItemsAfterEditEpic,
  getSharedLinksEpic
);
