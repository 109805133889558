import * as actionTypes from "../constants/actionTypes";

export interface SelectPropertyAction {
  type: typeof actionTypes.SELECT_PROPERTY;
  payload: {
    id: string;
    values: string[];
  };
}

export type Action = SelectPropertyAction;

export function selectProperty(id: string, values: string[]): Action {
  return {
    type: actionTypes.SELECT_PROPERTY,
    payload: {
      id,
      values
    }
  };
}
