import * as actionTypes from "../constants/actionTypes";
import { push } from "connected-react-router";
import { User } from "../reducers/auth";

interface BasicAction {
  type: string;
}
export interface GetSessionTokenAction {
  type: typeof actionTypes.GET_SESSION_TOKEN;
  payload: {
    action?: BasicAction;
  };
}
export interface GetSessionTokenSuccessAction {
  type: typeof actionTypes.GET_SESSION_TOKEN_SUCCESS;
  payload: {
    token: string;
  };
}
export interface GetSessionTokenErrorAction {
  type: typeof actionTypes.GET_SESSION_TOKEN_ERROR;
  payload: {
    error: string;
    status: number;
  };
}

export interface GetNewSessionTokenAction {
  type: typeof actionTypes.GET_NEW_SESSION_TOKEN;
  payload: {
    action: BasicAction;
  };
}
export interface GetNewSessionTokenSuccessAction {
  type: typeof actionTypes.GET_NEW_SESSION_TOKEN_SUCCESS;
  payload: {
    token: string;
  };
}
export interface GetNewSessionTokenErrorAction {
  type: typeof actionTypes.GET_NEW_SESSION_TOKEN_ERROR;
  payload: {
    error: string;
    status: number;
  };
}
export interface LoginAction {
  type: typeof actionTypes.LOGIN;
  payload: {
    values: {
      userName: string;
      password: string;
      remember: boolean;
    };
    from: string;
  };
}
export interface LoginWithTokenAction {
  type: typeof actionTypes.LOGIN_WITH_TOKEN;
  payload: {
    access_token: string;
    refresh_token: string;
    from: string;
  };
}
export interface LoginSuccessAction {
  type: typeof actionTypes.LOGIN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
    expires_in: number;
  };
}
export interface LoginErrorAction {
  type: typeof actionTypes.LOGIN_ERROR;
  payload: {
    error: string;
    status: number;
  };
}
export interface GetUserAction {
  type: typeof actionTypes.GET_USER;
  payload: {
    access_token: string;
  };
}
export interface GetUserSuccessAction {
  type: typeof actionTypes.GET_USER_SUCCESS;
  payload: {
    user: User;
  };
}
export interface GetUserErrorAction {
  type: typeof actionTypes.GET_USER_ERROR;
  payload: {
    error: string;
    status: number;
  };
}
export interface RefreshTokenAction {
  type: typeof actionTypes.REFRESH_TOKEN;
  payload: {
    refresh_token: string;
  };
}
export interface RefreshTokenSuccessAction {
  type: typeof actionTypes.REFRESH_TOKEN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
    expires_in: number;
  };
}
export interface RefreshTokenErrorAction {
  type: typeof actionTypes.REFRESH_TOKEN_ERROR;
  payload: {
    error: string;
    status: number;
  };
}
export interface LogoutAction {
  type: typeof actionTypes.LOGOUT;
  payload: {
    action?: BasicAction;
  };
}
export interface LogoutSuccessAction {
  type: typeof actionTypes.LOGOUT_SUCCESS;
}
export interface LogoutErrorAction {
  type: typeof actionTypes.LOGOUT_ERROR;
  payload: {
    error: string;
    status: number;
  };
}
export interface RecoverPasswordAction {
  type: typeof actionTypes.RECOVER_PASSWORD;
  payload: {
    username: string;
    email: string;
  };
}
export interface RecoverPasswordSuccessAction {
  type: typeof actionTypes.RECOVER_PASSWORD_SUCCESS;
}
export interface RecoverPasswordErrorAction {
  type: typeof actionTypes.RECOVER_PASSWORD_ERROR;
  payload: {
    error: string;
    status: number;
  };
}
export interface AppTokenExpiredAction {
  type: typeof actionTypes.APP_TOKEN_EXPIRED;
}
export interface TokenExpiredAction {
  type: typeof actionTypes.TOKEN_EXPIRED;
}

export interface EmptyAction {
  type: "EMPTY";
}

export type Action =
  | GetSessionTokenAction
  | GetSessionTokenErrorAction
  | GetSessionTokenSuccessAction
  | GetNewSessionTokenAction
  | GetNewSessionTokenErrorAction
  | GetNewSessionTokenSuccessAction
  | LoginAction
  | LoginWithTokenAction
  | LoginSuccessAction
  | LoginErrorAction
  | GetUserAction
  | GetUserErrorAction
  | GetUserSuccessAction
  | RefreshTokenAction
  | RefreshTokenErrorAction
  | RefreshTokenSuccessAction
  | LogoutAction
  | LogoutSuccessAction
  | LogoutErrorAction
  | RecoverPasswordAction
  | RecoverPasswordSuccessAction
  | RecoverPasswordErrorAction
  | AppTokenExpiredAction
  | TokenExpiredAction
  | EmptyAction;

export function getSessionToken(
  action: BasicAction | undefined = undefined
): GetSessionTokenAction {
  return {
    type: actionTypes.GET_SESSION_TOKEN,
    payload: {
      action
    }
  };
}

export function getSessionTokenSuccess(
  token: string
): GetSessionTokenSuccessAction {
  return {
    type: actionTypes.GET_SESSION_TOKEN_SUCCESS,
    payload: {
      token
    }
  };
}

export function getSessionTokenError(
  error: string,
  status: number
): GetSessionTokenErrorAction {
  return {
    type: actionTypes.GET_SESSION_TOKEN_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function getNewSessionToken(
  action: BasicAction
): GetNewSessionTokenAction {
  return {
    type: actionTypes.GET_NEW_SESSION_TOKEN,
    payload: {
      action
    }
  };
}

export function getNewSessionTokenSuccess(
  token: string
): GetNewSessionTokenSuccessAction {
  return {
    type: actionTypes.GET_NEW_SESSION_TOKEN_SUCCESS,
    payload: {
      token
    }
  };
}

export function getNewSessionTokenError(
  error: string,
  status: number
): GetNewSessionTokenErrorAction {
  return {
    type: actionTypes.GET_NEW_SESSION_TOKEN_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function login(
  values: {
    userName: string;
    password: string;
    remember: boolean;
  },
  from: string
): LoginAction {
  return {
    type: actionTypes.LOGIN,
    payload: {
      values,
      from
    }
  };
}

export function loginWithToken(
  access_token: string,
  refresh_token: string,
  from: string = "/"
): LoginWithTokenAction {
  return {
    type: actionTypes.LOGIN_WITH_TOKEN,
    payload: {
      access_token,
      refresh_token,
      from
    }
  };
}

export function loginSuccess(
  access_token: string,
  refresh_token: string,
  expires_in: number
): LoginSuccessAction {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: {
      access_token,
      refresh_token,
      expires_in
    }
  };
}

export function loginError(error: string, status: number): LoginErrorAction {
  return {
    type: actionTypes.LOGIN_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function getUser(access_token: string): GetUserAction {
  return {
    type: actionTypes.GET_USER,
    payload: {
      access_token
    }
  };
}

export function getUserSuccess(user: User): GetUserSuccessAction {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    payload: {
      user
    }
  };
}

export function getUserError(
  error: string,
  status: number
): GetUserErrorAction {
  return {
    type: actionTypes.GET_USER_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function refreshToken(refresh_token: string): RefreshTokenAction {
  return {
    type: actionTypes.REFRESH_TOKEN,
    payload: {
      refresh_token
    }
  };
}

export function refreshTokenSuccess(
  access_token: string,
  refresh_token: string,
  expires_in: number
): RefreshTokenSuccessAction {
  return {
    type: actionTypes.REFRESH_TOKEN_SUCCESS,
    payload: {
      access_token,
      refresh_token,
      expires_in
    }
  };
}

export function refreshTokenError(
  error: string,
  status: number
): RefreshTokenErrorAction {
  return {
    type: actionTypes.REFRESH_TOKEN_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function logout(
  action: BasicAction | undefined = undefined
): LogoutAction {
  return {
    type: actionTypes.LOGOUT,
    payload: {
      action
    }
  };
}

export function logoutSuccess(): LogoutSuccessAction {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  };
}

export function logoutError(error: string, status: number): LogoutErrorAction {
  return {
    type: actionTypes.LOGOUT_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function recoverPassword(
  username: string,
  email: string
): RecoverPasswordAction {
  return {
    type: actionTypes.RECOVER_PASSWORD,
    payload: {
      username,
      email
    }
  };
}

export function recoverPasswordSuccess(): RecoverPasswordSuccessAction {
  return {
    type: actionTypes.RECOVER_PASSWORD_SUCCESS
  };
}

export function recoverPasswordError(
  error: string,
  status: number
): RecoverPasswordErrorAction {
  return {
    type: actionTypes.RECOVER_PASSWORD_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function appTokenExpired(): AppTokenExpiredAction {
  return {
    type: actionTypes.APP_TOKEN_EXPIRED
  };
}

export function tokenExpired(): TokenExpiredAction {
  return {
    type: actionTypes.TOKEN_EXPIRED
  };
}
export function empty(): EmptyAction {
  return {
    type: actionTypes.EMPTY
  };
}

export function redirectToPage(path: string) {
  return push(path);
}
