import * as actionTypes from "../constants/actionTypes";

export interface SetSearchStringAction {
  type: typeof actionTypes.SET_SEARCH_STRING;
  payload: { text: string };
}

export type Action = SetSearchStringAction;

export function setSearchString(text: string): Action {
  return {
    type: actionTypes.SET_SEARCH_STRING,
    payload: {
      text
    }
  };
}
