import React, { useState, Fragment } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Divider } from "antd";
// import { Divider, Switch, Button, Icon, Popover } from "antd";
import * as actions from "../../../actions";
import CategoryTreeSelect from "../category-tree-select";
import { getChildrenNode } from "../../../utils";
// useWhyDidYouUpdate } from "../../../hooks/useWhyDidYouUpdate";

import { FiltersState } from "../../../reducers/filters";
import { State, Dictionary } from "../../../interfaces";
import { CategoryAll } from "../../../reducers/categories";

interface Props {
  labels: Dictionary<string>;
}

const Categories: React.FC<Props> = props => {
  const { labels } = props;

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const {
    entities: categories,
    allById: categoriesById,
    selectedById
  } = filters.categories;

  const [selectAllChildren] = useState(true);
  // const [selectAllChildren, setSelectAllChildren] = useState(true);

  // const ToggleSelectAllChildren = (checked: boolean) => {
  //   categories.all.forEach(id => {
  //     onSelectCategory(id, [], []);
  //   });

  //   setSelectAllChildren(checked);
  // };

  const dispatch = useDispatch();

  const onCategoryChange = (values: string[], key: string) => {
    // console.log(`key= ${key} values= ${values}`);
    let children: string[] = [];
    // in caso di nodi slegati parent/childre usare:
    if (!selectAllChildren) {
      // children = values.map(node => node.value);
    } else {
      if (values.length > 0) {
        children = getChildrenNode(
          values,
          categoriesById as Dictionary<CategoryAll>
        );
      }
    }
    // console.log("children ", children);
    dispatch(actions.selectCategory(key, values, children));
  };

  return (
    <>
      {/* <div className="toggle-select-all-children">
          <span className="help-text">{labels.selectAllChildren} </span>
          <Switch
            checkedChildren={<Icon type="check" />}
            unCheckedChildren={<Icon type="close" />}
            defaultChecked
            onChange={ToggleSelectAllChildren}
          />
          <Popover
            placement="top"
            title={labels.selectAllChildren}
            content={
              <>
                <p>{labels.selectAllChildrenHelpTrue}</p>
                <p>{labels.selectAllChildrenHelpFalse}</p>
              </>
            }
            trigger="click"
          >
            <Button
              type="primary"
              size="small"
              shape="circle"
              icon="question-circle-o"
            />
          </Popover>
        </div> */}
      {categories.all.map((id: string) => {
        const { label, children, isDisabled } = categories.byId[id];
        const values = selectedById[id];
        return children && (!isDisabled || values.length > 0) ? (
          <Fragment key={id}>
            <Divider className="filters-label">{label}</Divider>
            <CategoryTreeSelect
              placeholder={labels.selectValues}
              treeData={children}
              selectedNodes={values}
              selectAllChildren={selectAllChildren}
              onSelectNode={(values, label, extra) =>
                onCategoryChange(values, id)
              }
            />
          </Fragment>
        ) : null;
      })}
    </>
  );
};

export default Categories;
