import React, { useState, useCallback } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Modal, Button, Select, Divider } from "antd";

import * as actions from "../../actions";

import { State } from "../../interfaces";
import { FiltersState } from "../../reducers/filters";
import { LanguageState } from "../../reducers/language";
import { ItemsState, Item } from "../../reducers/items";
import FileLoader from "../FileLoader";
// import { FilterOptions } from "antd/lib/select";

const Option = Select.Option;

const EditModal = () => {
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { tags: propTags } = filters;

  const _language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = _language;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const { itemToEdit: item, isLoading, showModal } = items;

  const dispatch = useDispatch();

  const onCloseModal = useCallback(
    (visible, item) => dispatch(actions.showEditModal(visible, item)),
    [dispatch]
  );

  const onEditTags = useCallback(
    (added, removed, id) =>
      dispatch(
        actions.getNewSessionToken(actions.editTagsForItem(added, removed, id))
      ),
    [dispatch]
  );

  const _tags = (item as Item) ? (item as Item).tags : [];
  const [tags, setTags] = useState<string[]>(_tags);

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // console.log("handleCancel ");
    onCloseModal(false, null);
  };

  const onSave = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const { id, tags: oldTags } = item as Item;
    // console.log("tags ", tags);
    // console.log("oldTags = ", oldTags);

    let newlist = new Set(tags);
    let oldlist = new Set(oldTags);

    let added = new Set([...newlist].filter((x) => !oldlist.has(x)));
    let removed = new Set([...oldlist].filter((x) => !newlist.has(x)));

    // console.log("added ", [...added]);
    // console.log("removed = ", [...removed]);
    onEditTags([...added], [...removed], id);
  };

  const tagChange = (value: any) => {
    setTags(value);
  };

  const filterOption = (input: string, option: any) => {
    return (
      (option?.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
      0
    );
  };

  if (!item) {
    return null;
  }
  const { name, thumbUrl } = item;
  return (
    <>
      {item && (
        <Modal
          title={name}
          visible={showModal}
          onCancel={handleCancel}
          footer={null}
          width={350}
        >
          {thumbUrl && thumbUrl !== "" && (
            <FileLoader type="tag" fileName={name} fileUrl={thumbUrl} />
          )}
          <Divider className="filters-label">Tags</Divider>
          <Select
            className="search-panel-element"
            value={tags}
            placeholder={labels.selectValues}
            onChange={tagChange}
            mode="multiple"
            allowClear={true}
            filterOption={filterOption}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            {propTags.entities.all.map((_id: string) => {
              const { id, name } = propTags.entities.byId[_id];
              return (
                <Option key={id} value={id.toString()}>
                  {name}
                </Option>
              );
            })}
          </Select>
          <Button
            type="primary"
            htmlType="submit"
            className="save-tags-btn"
            loading={isLoading}
            onClick={onSave}
          >
            {labels.save}
          </Button>
        </Modal>
      )}
    </>
  );
};

export default EditModal;
