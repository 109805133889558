import {
  CloseOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RightOutlined
} from "@ant-design/icons";
import { Button, Layout, Popover, Row, Tag } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, match } from "react-router-dom";
import Slider from "react-slick";
import { useMedia } from "react-use";
import "video-react/dist/video-react.css";

import * as actions from "../../actions";
import DownloadBtn from "../../components/downloadbtn";
import ShareBtn from "../../components/share/shareBtn";
import { getPrevNextItem } from "../../selectors";
import { encrypt, formatDate } from "../../utils";

import { FiltersState } from "../../reducers/filters";
import { Item, ItemsState } from "../../reducers/items";
import { LanguageState } from "../../reducers/language";
import { PrevNextItem } from "../../selectors/items";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import FileLoader from "../../components/FileLoader";
import {
  logTMDataLayer,
  useLogTMDataLayer
} from "../../hooks/useLogTMDataLayer";
import { State } from "../../interfaces";
import { AuthState } from "../../reducers/auth";
const { Content, Header } = Layout;

interface Props {
  match: match<any>;
}

const Details: React.FC<Props> = (props) => {
  const thumbs = useRef<any>();
  const { match } = props;

  const [type, setType] = useState("image");
  const isSmall = useMedia("(max-width: 768px)");

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { byId: tagById } = filters.tags.entities;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );
  const { entities, selectedItem, userCanShare } = items;

  const nav: PrevNextItem = useSelector(
    (state: State) => getPrevNextItem(state.items),
    shallowEqual
  );

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { user } = auth;

  useEffect(() => {
    logTMDataLayer({
      dataLayer: {
        event: "init",
        userId: user?.email ? encrypt(user?.email as string) : undefined,
        user_type: user?.role?.[0],
        pageType: "details",
        language: language.selectedLanguage?.id,
        country: undefined,
        loginStatus: !!user ? "Logged" : "Guest"
      },
      dataLayerName: "dataLayer"
    });
  }, [user, language]);

  const dispatch = useDispatch();

  const intervalRef = useRef<any>();

  useEffect(() => {
    const getItemFromID = (id: string) => {
      if (selectedItem !== null && selectedItem !== undefined) {
        const index = entities.all.indexOf(selectedItem.id);
        intervalRef.current = setTimeout(
          () => thumbs!.current.slickGoTo(index),
          300
        );
      } else {
        dispatch(actions.getItemById(id));
      }
    };
    getItemFromID(match.params.itemId);

    return () => clearTimeout(intervalRef.current);
  }, [entities.all, match.params.itemId, dispatch, selectedItem]);

  useEffect(() => {
    if (selectedItem) {
      const index = selectedItem.previewType.indexOf("/");
      setType(selectedItem.previewType.substring(0, index));
      // console.log("selectedItem ", type);
    }
  }, [selectedItem]);

  const onBack = () => {
    dispatch(actions.selectItem(null));
  };

  const onPrev = () => {
    thumbs?.current.slickPrev();
    dispatch(actions.selectItem(nav.prevItem));
  };

  const onNext = () => {
    thumbs?.current.slickNext();
    dispatch(actions.selectItem(nav.nextItem));
  };

  const onSelectThumb = useCallback(
    (item) => {
      dispatch(actions.selectItem(item));
    },
    [dispatch]
  );

  const handleOnShare = () => {
    dispatch(actions.showShareModal(true, selectedItem));
  };

  useLogTMDataLayer({
    dataLayer: {
      event: "view_item",
      ecommerce: {
        items: [
          {
            item_name: undefined,
            item_id: selectedItem?.name,
            price: 1,
            quantity: 1,
            item_category: selectedItem?.tags?.[0],
            item_category2: undefined
          }
        ]
      }
    },
    dataLayerName: "dataLayer"
  });

  // console.log("object");

  return (
    <Layout className="details">
      <Header style={{ position: "fixed", width: "100%" }}>
        {selectedItem && (
          <Row justify="space-between" align="middle">
            <Link to="/home" onClick={onBack}>
              <Button className="nav-bnt back-btn" type="default">
                <CloseOutlined />
              </Button>
            </Link>
            <h2 className="details-title">{selectedItem.name}</h2>

            {!isSmall ? (
              <Popover
                overlayClassName="popover-item-info"
                placement="bottomRight"
                getPopupContainer={(triggerNode) =>
                  triggerNode.parentNode as HTMLElement
                }
                content={
                  <>
                    <h4>{formatDate(selectedItem.publishDate)}</h4>
                    {selectedItem.tags &&
                      selectedItem.tags.map((tag) => {
                        return (
                          <Tag key={tag} className="item-tag" color="#999">
                            {tagById && tagById[tag].name}
                          </Tag>
                        );
                      })}
                  </>
                }
              >
                <Button className="info-btn">
                  <InfoCircleOutlined />
                </Button>
              </Popover>
            ) : null}
            {labels && (
              <DownloadBtn
                direction="bottomRight"
                files={selectedItem.files}
                label={isSmall ? "" : `${labels.download}`}
                itemID={selectedItem.id}
              />
            )}
            {labels && userCanShare && (
              <ShareBtn
                direction="bottomRight"
                files={selectedItem.files}
                itemID={selectedItem.id}
                onShare={handleOnShare}
              />
            )}
          </Row>
        )}
      </Header>
      <Content className="details-content">
        {selectedItem && (
          <div className="details-wrapper">
            <div className="thumbs-wrapper">
              <Link
                to={`/details/${nav.prevItem !== null ? nav.prevItem?.id : ""}`}
                onClick={onPrev}
              >
                <Button
                  className="nav-bnt"
                  type="default"
                  disabled={nav.prevItem === null}
                  size="large"
                >
                  <LeftOutlined />
                  {/* {isSmall ? "" : "Prev"} */}
                </Button>
              </Link>
              <div className="thumbs">
                <Slider
                  ref={thumbs}
                  autoplay={false}
                  speed={500}
                  slidesToShow={5}
                  slidesToScroll={1}
                  arrows={false}
                  responsive={[
                    { breakpoint: 768, settings: { slidesToShow: 3 } },
                    { breakpoint: 1024, settings: { slidesToShow: 5 } }
                  ]}
                >
                  {entities.all.map((itemId: string) => {
                    const item: Item = entities.byId[itemId];

                    return (
                      <Link
                        key={itemId}
                        to={`/details/${itemId}`}
                        onClick={() => onSelectThumb(item)}
                      >
                        <FileLoader
                          clssName={
                            "thumb-image " +
                            (itemId === selectedItem.id ? "active" : "")
                          }
                          fileUrl={item.thumbUrl}
                          type="css"
                        />
                      </Link>
                    );
                  })}
                </Slider>
              </div>
              <Link
                to={`/details/${nav.nextItem !== null ? nav.nextItem?.id : ""}`}
                onClick={onNext}
              >
                <Button
                  className="nav-bnt"
                  type="default"
                  disabled={nav.nextItem === null}
                  size="large"
                >
                  {/* {isSmall ? "" : "Next"} */}
                  <RightOutlined />
                </Button>
              </Link>
            </div>
            {type === "image" ? (
              <FileLoader
                clssName="detail-image"
                fileUrl={selectedItem.previewUrl}
                type="css"
              />
            ) : (
              <FileLoader
                clssName="detail-video vjs-big-play-centered"
                fileUrl={selectedItem.previewUrl}
                type="video"
                poster={selectedItem.previewPosterUrl}
              />
            )}
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Details;
