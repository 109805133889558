import { createSelector } from "reselect";
import { Item, ItemsState } from "../reducers/items";

export type PrevNextItem = {
  prevItem?: Item | null;
  nextItem?: Item | null;
};

const getEntities = (state: ItemsState) => state.entities;
const getSelectedItem = (state: ItemsState) => state.selectedItem;

export const getPrevNextItem = createSelector(
  [getEntities, getSelectedItem],
  (entities, item) => {
    const { all, byId } = entities;

    let prevItem = null;
    let nextItem = null;

    if (item && (item as Item).id && all.length > 0) {
      const itemID = (item as Item).id;
      const index = all.indexOf(itemID);

      const prev = index - 1;
      prev > -1 ? (prevItem = byId[all[prev]]) : (prevItem = null);

      const next = index + 1;
      next < all.length ? (nextItem = byId[all[next]]) : (nextItem = null);
    }

    return {
      prevItem,
      nextItem
    };
  }
);
