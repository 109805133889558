import { combineReducers } from "redux";
import * as actionTypes from "../constants/actionTypes";
import { disableFlatNode } from "../utils";
import { Action as TagsAction } from "../actions/tags";
import { Action as FiltersAction } from "../actions/filters";
import { Action as LanguagesAction } from "../actions/language";
import { Action as ItemsAction } from "../actions/items";
import { Action as MarketsAction } from "../actions/markets";
import { Entities, Tag } from "../interfaces";

export type Action =
  | TagsAction
  | LanguagesAction
  | FiltersAction
  | ItemsAction
  | MarketsAction;

export type TagsState = ReturnType<typeof Tags>;

const entities = (
  state: Entities<Tag> = {
    all: [],
    byId: {}
  },
  action: Action
) => {
  switch (action.type) {
    case actionTypes.GET_FILTERS_SUCCESS:
      const { all, byId } = action.payload.tags;
      return {
        all,
        byId
      };
    case actionTypes.SEARCH_ITEMS_SUCCESS:
      // se la pagina non è 0, vuol dire che stiamo paginando con gli stesso criteri di ricerca e quindi non serve ricalcolare i filtri
      const { page } = action.payload;
      if (page > 0) {
        return {
          all: state.all,
          byId: state.byId
        };
      }

      // se è una nuova ricerca disabilito i tags su cui non ho risulta
      const { tags } = action.payload.filters;
      const { all: ids, byId: tagById } = state;
      ids.forEach(id => {
        if (tagById && tagById[id])
          tagById[id] = disableFlatNode(tagById[id], tags) as Tag;
      });
      return {
        all: ids,
        byId: tagById
      };

    case actionTypes.SET_FILTERS_AFTER_EDIT:
      const { tags: _tags } = action.payload.filters;
      const { all: _ids, byId: _tagById } = state;
      console.log("action.payload ", action.payload);
      const newTagById = { ..._tagById };
      _ids.forEach(id => {
        if (newTagById && newTagById[id])
          newTagById[id] = disableFlatNode(newTagById[id], _tags) as Tag;
      });
      return {
        all: [..._ids],
        byId: newTagById
      };

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return {
        all: [],
        byId: {}
      };
    default:
      return state;
  }
};

const selectedTags = (state: string[] = [], action: Action) => {
  switch (action.type) {
    case actionTypes.SELECT_TAG:
      const { ids } = action.payload;
      return ids;

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return [];
    default:
      return state;
  }
};

const hasResults = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ITEMS_SUCCESS:
      // se è una nuova ricerca disabilito i tags su cui non ho risulta
      const { tags } = action.payload.filters;

      return tags.length > 0;

    case actionTypes.GET_FILTERS:
    case actionTypes.SELECT_LANGUAGE:
      return false;
    default:
      return state;
  }
};

const Tags = combineReducers({
  entities,
  selectedTags,
  hasResults
});

export default Tags;
