import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Divider, Tag } from "antd";
import * as actions from "../../../actions";
// import { useWhyDidYouUpdate } from "../../../hooks/useWhyDidYouUpdate";

import { FiltersState } from "../../../reducers/filters";
import { Dictionary, State } from "../../../interfaces";

const { CheckableTag } = Tag;

interface Props {
  labels?: Dictionary<string>;
}

let Markets: React.FC<Props> = props => {
  const { labels } = props;
  console.log("market");
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { entities: values, selectedMarkets: selectedValues } = filters.markets;

  const dispatch = useDispatch();

  const _onChange = (id: string) => {
    dispatch(actions.toggleMarketOnSelectedList(id));
  };

  const _onReset = () => {
    dispatch(actions.setMarket([]));
  };

  // useWhyDidYouUpdate("markets", props);
  // console.log("markets render");

  const resetMarkets = selectedValues.length ? (
    <CloseCircleOutlined onClick={_onReset} />
  ) : null;

  return (
    <>
      {values.all.length > 0 && (
        <>
          {values.all.length > 1 ? (
            <>
              <Divider className="filters-label">
                {labels?.markets} {resetMarkets}
              </Divider>
              <div className="markets-wrapper search-panel-element">
                {values.all.map(_id => {
                  const { id, name } = values.byId[_id];
                  return (
                    <CheckableTag
                      key={id}
                      className="market-tag"
                      checked={selectedValues.indexOf(id) > -1}
                      onChange={(checked: boolean) => _onChange(id)}
                    >
                      {name}
                    </CheckableTag>
                  );
                })}
              </div>
            </>
          ) : (
            <Divider className="filters-label">
              {values.byId[values.all[0]].name} {labels?.market}
            </Divider>
          )}
        </>
      )}
    </>
  );
};

export default Markets;
