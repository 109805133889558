import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as actions from "../../actions";
import { Loader } from "../../components/loader";
import { logTMDataLayer } from "../../hooks/useLogTMDataLayer";
import { State } from "../../interfaces";
import { AuthState } from "../../reducers/auth";
import { LanguageState } from "../../reducers/language";
import { encrypt } from "../../utils";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 }
};

interface Props {
  location: any;
}

const LoginPage: React.FC<Props> = (props) => {
  const { location } = props;

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { user, isFetching, rememberUser } = auth;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;

  useEffect(() => {
    if (language.selectedLanguage) {
      logTMDataLayer({
        dataLayer: {
          event: "init",
          userId: user?.email ? encrypt(user?.email as string) : undefined,
          user_type: user?.role?.[0],
          pageType: "login",
          language: language.selectedLanguage?.id,
          country: undefined,
          loginStatus: !!user ? "Logged" : "Guest"
        },
        dataLayerName: "dataLayer"
      });
    }
  }, [user, language.selectedLanguage]);

  const dispatch = useDispatch();

  const onLogin = useCallback(
    (values, from) => dispatch(actions.login(values, from)),
    [dispatch]
  );

  const onFinish = (values: any) => {
    // console.log("Success:", values);
    const from = location.state ? location.state.from.pathname : null;
    onLogin(values, from);
  };

  const localAuth = localStorage.getItem("auth");
  const isLoading = localAuth !== null;

  return (
    <>
      {!isLoading && (
        <Row className="login" align="middle">
          <Col xs={{ span: 22, offset: 1 }} sm={{ span: 8, offset: 8 }}>
            {labels && (
              <Form
                {...layout}
                className="login-form"
                initialValues={{ remember: rememberUser }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: `${labels.requiredFiels}`
                    }
                  ]}
                >
                  <Input
                    prefix={<UserOutlined style={{ fontSize: 13 }} />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: `${labels.requiredFiels}`
                    }
                  ]}
                >
                  <Input
                    prefix={<LockOutlined style={{ fontSize: 13 }} />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="wrapper">
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>{labels.rememberMe}</Checkbox>
                    </Form.Item>

                    <Link className="login-form-forgot" to="/forgot-password">
                      {labels.forgotPassword}
                    </Link>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isFetching}
                    // disabled={hasErrors(getFieldsError())}
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Col>
        </Row>
      )}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default LoginPage;
