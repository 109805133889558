
import React from "react";
import { Tag } from "antd";

interface Props {
  id: string;
  itemId: string;
  name: string;
  tagClicked: Function;
}
// type State = {
//   isActive: boolean
// };

const ItemTag: React.FC<Props> = props => {
  const { id, name, tagClicked } = props;
  // const [isActive] = useState(true);

  const _onClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    tagClicked(id);
  };

  return (
    <Tag className="item-tag" color="#999" onClick={_onClick}>
      {name}
    </Tag>
  );
};

export default ItemTag;
