import React, { useCallback, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Input } from "antd";
import * as actions from "../../actions";
import { Loader } from "../loader";
import Markets from "./markets";
import Typologies from "./typologies";
import Categories from "./categories";
import Tags from "./tags";
import Properties from "./properties";

import { LanguageState } from "../../reducers/language";
import { FiltersState } from "../../reducers/filters";
import { State } from "../../interfaces";

const Search = Input.Search;

interface Props {}

const SearchPanel: React.FC<Props> = props => {
  console.log("SearchPanel");
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { isLoading, searchString } = filters;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;
  //
  const dispatch = useDispatch();

  const onSetSearchString = useCallback(
    text => dispatch(actions.setSearchString(text)),
    [dispatch]
  );

  const textSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSetSearchString(event.target.value);
  };
  // const onTextSearch = value => {
  //   onSetSearchString(value);
  // };
  let searchInput = useRef<any>();

  const emitEmptySearch = () => {
    // console.log("searchInput ", searchInput);
    if (searchInput && searchInput.current) searchInput.current.focus();
    onSetSearchString("");
  };

  // const defaultMarket = markets.all[0];
  const { text: _searchString } = searchString;
  const prefix = _searchString ? (
    <CloseCircleOutlined onClick={emitEmptySearch} />
  ) : null;

  return (
    <>
      {!isLoading && labels && (
        <div className="search-wrapper">
          <Search
            style={{
              marginBottom: 20
            }}
            placeholder={labels.searchText}
            prefix={prefix}
            value={searchString.text}
            onChange={textSearchChange}
            ref={searchInput}
            // onSearch={onTextSearch}
          />
          {labels && !isLoading && (
            <>
              <Markets labels={labels} />
              <Typologies labels={labels} />
              <Categories labels={labels} />
              <Tags labels={labels} />
              <Properties labels={labels} />
            </>
          )}
        </div>
      )}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default SearchPanel;
