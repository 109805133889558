import { CloseCircleOutlined } from "@ant-design/icons";
import { Divider, Tag, Tooltip } from "antd";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useMedia } from "react-use";
import * as actions from "../../../actions";

// import { useWhyDidYouUpdate } from "../../../hooks/useWhyDidYouUpdate";

import { Dictionary, State } from "../../../interfaces";
import { FiltersState } from "../../../reducers/filters";
import { detectIE11 } from "../../../utils";
import DynamicIcon from "../../DynamicIcon";

const { CheckableTag } = Tag;

interface Props {
  labels?: Dictionary<string>;
}

let Typologies: React.FC<Props> = (props) => {
  const { labels } = props;

  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { entities: values, selectedTypologies: selectedValues } =
    filters.typologies;

  const hasHover = useMedia("(hover: hover)");

  const dispatch = useDispatch();

  const toggle = (id: string) => {
    dispatch(actions.toggleTypologyOnSelectedList(id));
  };

  const _onReset = () => {
    dispatch(actions.setTypology([]));
  };

  const reset = selectedValues.length ? (
    <CloseCircleOutlined onClick={_onReset} />
  ) : null;

  // Internet Explorer 11
  const isIE = detectIE11();
  return (
    <>
      <Divider className="filters-label">
        {labels?.typologies} {reset}
      </Divider>
      <div className="typology-wrapper search-panel-element">
        {values.all.map((_id: string) => {
          const { id, name, icon } = values.byId[_id];
          return hasHover || isIE ? (
            <Tooltip
              key={id}
              title={name}
              placement="topLeft"
              arrowPointAtCenter
              mouseLeaveDelay={0}
              getPopupContainer={(triggerNode) =>
                triggerNode.parentNode as HTMLElement
              }
            >
              <CheckableTag
                className="typology-tag"
                checked={selectedValues.indexOf(id) > -1}
                onChange={(checked) => toggle(id)}
              >
                <DynamicIcon
                  type={icon}
                  isChecked={selectedValues.indexOf(id) > -1}
                />
              </CheckableTag>
            </Tooltip>
          ) : (
            <CheckableTag
              key={id}
              className="typology-tag"
              checked={selectedValues.indexOf(id) > -1}
              onChange={(checked) => toggle(id)}
            >
              <DynamicIcon
                type={icon}
                isChecked={selectedValues.indexOf(id) > -1}
              />
            </CheckableTag>
          );
        })}
      </div>
    </>
  );
};

export default Typologies;
