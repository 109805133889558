import * as actionTypes from "../constants/actionTypes";

export interface SelectCategoryAction {
  type: typeof actionTypes.SELECT_CATEGORY;
  payload: {
    id: string;
    values: string[];
    children: string[];
  };
}

export type Action = SelectCategoryAction;

export function selectCategory(
  id: string,
  values: string[],
  children: string[]
): Action {
  return {
    type: actionTypes.SELECT_CATEGORY,
    payload: {
      id,
      values,
      children
    }
  };
}
