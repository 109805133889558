import React, { Fragment } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Divider } from "antd";
import * as actions from "../../../actions";
import CategoryTreeSelect from "../category-tree-select";
// import { useWhyDidYouUpdate } from "../../../hooks/useWhyDidYouUpdate";

import { FiltersState } from "../../../reducers/filters";
import { State, Dictionary } from "../../../interfaces";

interface Props {
  labels: Dictionary<string>;
}

const Properties: React.FC<Props> = props => {
  const { labels } = props;
  console.log("Properties");
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const {
    entities: properties,
    selectedById: propSelectedById
  } = filters.properties;

  const dispatch = useDispatch();

  const onPropertyChange = (values: string[], key: string) => {
    dispatch(actions.selectProperty(key, values));
  };
  return (
    <>
      {properties.all.map(id => {
        const { label, children, disabled } = properties.byId[id];
        const values = propSelectedById[id];
        return children && (!disabled || values.length > 0) ? (
          <Fragment key={id}>
            <Divider className="filters-label">{label}</Divider>
            <CategoryTreeSelect
              placeholder={labels.selectValues}
              treeData={children}
              selectedNodes={values}
              selectAllChildren={true}
              onSelectNode={values => onPropertyChange(values, id)}
            />
          </Fragment>
        ) : null;
      })}
    </>
  );
};

export default Properties;
