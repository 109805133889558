import { BankOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { push } from "connected-react-router";
import React, { useCallback, useEffect, useState } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdRssFeed } from "react-icons/md";
import Masonry from "react-masonry-css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions";
import DynamicIcon from "../../components/DynamicIcon";
import { Loader } from "../../components/loader";
import { BROCHURE_BANK_URL, PRESS_KIT_URL } from "../../constants/actionTypes";
import { logTMDataLayer } from "../../hooks/useLogTMDataLayer";
import { Entities, State, Typology } from "../../interfaces";
import { AuthState } from "../../reducers/auth";
import { LanguageState } from "../../reducers/language";
import { encrypt } from "../../utils";

const breakpointColumnsObj = {
  default: 4,
  "900": 2,
  "576": 1
};

let Welcome = () => {
  const [items, setItems] = useState<string[]>([]);

  const typologies = useSelector(
    (state: State) => state.filters.typologies,
    shallowEqual
  );

  const entities: Entities<Typology> = typologies.entities;
  const { isLoading } = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );

  const dispatch = useDispatch();
  const onSetType = useCallback(
    (id) => {
      const ids = [id];
      dispatch(actions.setTypology(ids));
      dispatch(push("/home"));
    },
    [dispatch]
  );

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { user } = auth;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );

  useEffect(() => {
    logTMDataLayer({
      dataLayer: {
        event: "init",
        userId: user?.email ? encrypt(user?.email as string) : undefined,
        user_type: user?.role?.[0],
        pageType: "welcome",
        language: language.selectedLanguage?.id,
        country: undefined,
        loginStatus: !!user ? "Logged" : "Guest"
      },
      dataLayerName: "dataLayer"
    });
  }, [user, language]);

  useEffect(() => {
    if (entities && entities.all.length > 0) {
      setItems([...entities.all, "pk", "library"]);
    }
  }, [entities]);
  return (
    <>
      {!isLoading && (
        <Masonry
          className="home"
          columnClassName="masonry-column"
          breakpointCols={breakpointColumnsObj}
        >
          {items.map((_id) => {
            if (_id === "pk") {
              return (
                <a
                  key={_id}
                  className="welcome-card"
                  href={PRESS_KIT_URL}
                  target="_blanck"
                >
                  <Card>
                    <div className="custom-image">
                      <MdRssFeed className="icon" />
                    </div>
                    <div className="custom-card">
                      <h3>
                        Press Kit{" "}
                        <FaExternalLinkAlt className="external-link" />
                      </h3>
                      {/* <p className="description">Press Kit</p> */}
                    </div>
                  </Card>
                </a>
              );
            } else if (_id === "library") {
              return (
                <a
                  key={_id}
                  className="welcome-card"
                  href={BROCHURE_BANK_URL}
                  target="_blanck"
                >
                  <Card>
                    <div className="custom-image">
                      <BankOutlined className="icon" />
                    </div>
                    <div className="custom-card">
                      <h3>
                        Brochure Bank{" "}
                        <FaExternalLinkAlt className="external-link" />
                      </h3>
                      {/* <p className="description">Brochure Bank</p> */}
                    </div>
                  </Card>
                </a>
              );
            } else if (entities.byId[_id]) {
              const { id, name, description, icon } = entities.byId[_id];
              return (
                <Card
                  key={id}
                  className="welcome-card"
                  onClick={() => onSetType(id)}
                >
                  <div className="custom-image">
                    <DynamicIcon type={icon} isChecked={true} />
                  </div>
                  <div className="custom-card">
                    <h3>{name}</h3>
                    {description && (
                      <div
                        className="description"
                        title={description.replace(/<\/?[^>]+(>|$)/g, "")}
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )}
                  </div>
                </Card>
              );
            } else {
              return null;
            }
          })}
        </Masonry>
      )}
      <Loader isLoading={isLoading} />
    </>
  );
};

export default Welcome;
