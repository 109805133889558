import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Select, Divider } from "antd";
// import { OptionProps } from "antd/lib/select";
import * as actions from "../../../actions";
// import { useWhyDidYouUpdate } from "../../../hooks/useWhyDidYouUpdate";

import { FiltersState } from "../../../reducers/filters";
import { State, Dictionary } from "../../../interfaces";
// import { OptionType } from "antd/lib/select";

const Option = Select.Option;

interface Props {
  labels: Dictionary<string>;
}

const Tags: React.FC<Props> = props => {
  const { labels } = props;

  console.log("tag");
  const filters: FiltersState = useSelector(
    (state: State) => state.filters,
    shallowEqual
  );
  const { tags } = filters;

  const dispatch = useDispatch();

  const tagChange = (value: string[]) => {
    dispatch(actions.setTags(value));
  };

  const filterOption = (
    input: string,
    option: any
    // option: OptionType
  ) => {
    return (
      (option.children as string).toLowerCase().indexOf(input.toLowerCase()) >=
      0
    );
  };

  return (
    <>
      {tags.entities.all.length > 0 &&
        (tags.hasResults || tags.selectedTags.length > 0) && (
          <>
            <Divider className="filters-label">Tags</Divider>
            <Select
              className="search-panel-element"
              value={tags.selectedTags}
              placeholder={labels.selectValues}
              onChange={tagChange}
              mode="multiple"
              allowClear={true}
              filterOption={filterOption}
              getPopupContainer={triggerNode =>
                triggerNode.parentNode as HTMLElement
              }
            >
              {tags.entities.all.map((_id: string) => {
                const { id, name, disabled } = tags.entities.byId[_id];
                return (
                  <Option key={id} value={id.toString()} disabled={disabled}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </>
        )}
    </>
  );
};

export default Tags;
