import { Row } from "antd";
import React, { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions";

import { logTMDataLayer } from "../../hooks/useLogTMDataLayer";
import { State } from "../../interfaces";
import { AuthState } from "../../reducers/auth";
import { LanguageState } from "../../reducers/language";
import { encrypt } from "../../utils";

interface Props {
  match: any;
}

// const  = ({ onLoginWithToken, ...rest }) => {
const DealerPortalAccess: React.FC<Props> = (props) => {
  const { match } = props;
  const { access_token, refresh_token } = match.params;

  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { selectedLanguage } = language;

  const dispatch = useDispatch();

  const onLoginWithToken = useCallback(
    (access_token, refresh_token) =>
      dispatch(actions.loginWithToken(access_token, refresh_token)),
    [dispatch]
  );

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { user } = auth;

  useEffect(() => {
    logTMDataLayer({
      dataLayer: {
        event: "init",
        userId: user?.email ? encrypt(user?.email as string) : undefined,
        user_type: user?.role?.[0],
        pageType: "dealerportalaccess",
        language: language.selectedLanguage?.id,
        country: undefined,
        loginStatus: !!user ? "Logged" : "Guest"
      },
      dataLayerName: "dataLayer"
    });
  }, [user, language.selectedLanguage]);

  useEffect(() => {
    localStorage.clear();
    console.log("selectedLanguage ", selectedLanguage);
    console.log("access_token.. ", access_token);
    console.log("refresh_token.. ", refresh_token);
    if (selectedLanguage) {
      if (access_token) {
        onLoginWithToken(access_token, refresh_token);
      }
    }
  }, [access_token, onLoginWithToken, refresh_token, selectedLanguage]);

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { selectedLanguage: prevLang } = prevProps;
  //   const { selectedLanguage } = this.props;
  //   if (prevLang !== selectedLanguage) {
  //     const { access_token, refresh_token } = this.props.match.params;
  //     // console.log("access_token.. ", access_token);
  //     // console.log("refresh_token.. ", refresh_token);
  //     if (access_token) {
  //       const { onLoginWithToken } = this.props;
  //       onLoginWithToken(access_token, refresh_token);
  //     }
  //   }
  // }
  return (
    <Row align="middle" justify="center">
      <h3>Dealer portal access, please wait...</h3>
    </Row>
  );
};

export default DealerPortalAccess;
