import Icon, {
  BookOutlined,
  CalendarOutlined,
  CopyOutlined,
  FolderOpenOutlined,
  PictureOutlined,
  SolutionOutlined,
  UserOutlined,
  VideoCameraOutlined
} from "@ant-design/icons";
import React from "react";
import { ReactComponent as SocialGrayIcon } from "../../images/typologies/social_grey.svg";
import { ReactComponent as SocialOrangeIcon } from "../../images/typologies/social_orange.svg";

interface Props {
  type: string;
  isChecked?: boolean;
}

const DynamicIcon: React.FC<Props> = (props) => {
  const { type, isChecked } = props;
  switch (type) {
    case "picture":
      return <PictureOutlined className="icon" />;

    case "video-camera":
      return <VideoCameraOutlined className="icon" />;

    case "user":
      return <UserOutlined className="icon" />;

    case "copy":
      return <CopyOutlined className="icon" />;

    case "calendar":
      return <CalendarOutlined className="icon" />;

    case "credit-card":
      // return <CreditCardOutlined className="icon" />;
      return (
        <Icon
          component={isChecked ? SocialOrangeIcon : SocialGrayIcon}
          className="icon"
        />
      );

    case "folder-open":
      return <FolderOpenOutlined className="icon" />;

    case "dealer-pack":
      return <SolutionOutlined className="icon" />;

    default:
      return <BookOutlined className="icon" />;
  }
};

export default DynamicIcon;
