import { Epic } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import {
  catchError,
  debounceTime,
  filter,
  map,
  mapTo,
  switchMap
} from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import * as actions from "../actions";
import { Action as FilterAction } from "../actions/filters";
import { Action } from "../actions/items";
import * as actionTypes from "../constants/actionTypes";
import { State } from "../interfaces";
import { FiltersState } from "../reducers/filters";
import { Item } from "../reducers/items";
import domain from "../utils/domain";
import { normalizeItems } from "../utils/norm";

export type ActionWithFilters = Action | FilterAction;

export const setFilterForSearchEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    filter(
      isOfType([
        actionTypes.SET_SEARCH_STRING,
        actionTypes.SET_TYPOLOGY,
        actionTypes.TOGGLE_TYPOLOGY_ON_SELECTED_LIST,
        actionTypes.SELECT_CATEGORY,
        actionTypes.SELECT_PROPERTY,
        actionTypes.SELECT_TAG
      ])
    ),
    debounceTime(300),
    mapTo(actions.resetPageForItems())
  );

export const resetPageForItemsEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    filter(isOfType(actionTypes.RESET_PAGE_FOR_ITEMS)),
    mapTo(actions.searchItems())
  );

/*
 uso debounceTime per non fare richieste multiple al server anche se con 
 switchMap vengono cancellate e ricevo solo il risultato dell'ultima
*/
export const fetchItemsEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(
      isOfType([actionTypes.GET_FILTERS_SUCCESS, actionTypes.SEARCH_ITEMS])
    ),
    switchMap((action) => {
      /*
        ${domain}item/list
        parameters:
        
          page
          pageSize
          searchString
          market
          categories
          tags
          types
          sortBy (name/date)
      */
      const state = state$.value;
      const { auth, language } = state;
      const { accessToken, sessionToken } = auth;

      const { items } = state;
      // setto parametri di ricerca
      const { filters } = state;
      const _filters = setFiltersForSearch(filters);

      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/list?_format=json`,
        body: {
          lang: language.selectedLanguage?.id,
          page: items.page,
          pageSize: items.itemsPerPage,
          sortBy: "date",
          ..._filters
        }
      }).pipe(
        map((e) => {
          const { results } = e.response;
          const { items, page, total, filters, share_pr } = results;
          // console.log("items >> ", results);
          // prima di normalizzare i dati controllo se almeno uno è modificabile e setto userCanEdit
          let userCanEdit = false;

          items.forEach((item: Item) => {
            if (item.can_edit) {
              userCanEdit = true;
              return;
            }
          });

          const userCanShare = share_pr;

          const entities = normalizeItems(items);
          return actions.searchItemsSuccess({
            entities,
            page,
            total,
            filters,
            userCanEdit,
            userCanShare
          });
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.searchItemsError(message, error.status));
        })
      );
    })
  );

const setFiltersForSearch = (filters: FiltersState) => {
  // categorie
  const { entities } = filters.categories;
  const { selectedByIdWithChildren } = filters.categories;
  const selectedCategoriesValues: any = entities.all.map(
    (id: string) => selectedByIdWithChildren[id]
  );
  const categories = [].concat(...selectedCategoriesValues);
  // properties
  // const { entities: entitiesProperties } = filters.properties;
  const { selectedById: propertiesSelectedById } = filters.properties;
  // const selectedPropertiesValues = entitiesProperties.all.map(
  //   id => propertiesSelectedById[id]
  // );
  // const properties = [].concat(...selectedPropertiesValues);

  const properties = propertiesSelectedById;
  // typologies
  const { typologies } = filters;
  const types = typologies.selectedTypologies;
  // market
  const market = filters.markets.selectedMarkets;
  // tags
  const tags = filters.tags.selectedTags;
  // searchString
  const searchString = filters.searchString.text;

  const _filters = {
    searchString,
    types,
    categories,
    properties,
    market,
    tags
  };
  console.log("selectedFilters ", _filters);
  return _filters;
};

export const fetchNextPageEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_NEXT_PAGE_FOR_ITEMS)),
    mapTo(actions.searchItems())
  );

export const fetchItemByIdEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_ITEM_BY_ID)),
    switchMap((action) => {
      const state = state$.value;
      const { auth, language } = state;
      const { accessToken, sessionToken } = auth;

      const { itemID } = action.payload;
      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/list?_format=json`,
        body: {
          lang: language.selectedLanguage?.id,
          page: 0,
          pageSize: 1,
          itemid: itemID
        }
      }).pipe(
        map(({ response }) => {
          // gestisco il caso non ci sia item
          if (response.results.items.length < 1)
            return actions.getItemByIdError(`no item for ID ${itemID}`, 400);

          return actions.getItemByIdSuccess(response.results.items[0]);
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.getItemByIdError(message, error.status));
        })
      );
    })
  );

export const removeItemByIdEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.UNPUBLISH_ITEM)),
    switchMap((action) => {
      const state = state$.value;
      const { auth } = state;
      const { accessToken, sessionToken } = auth;

      const { itemID } = action.payload;
      return ajax({
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/item_data?_format=json`,
        body: {
          itemID,
          oper: "unpublish"
        }
      }).pipe(
        map(({ response }) => {
          return actions.unpublishItemSuccess(itemID);
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.unpublishItemError(message, error.status));
        })
      );
    })
  );

export const editTagsForItemEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.EDIT_TAGS_FOR_ITEM)),
    switchMap((action) => {
      const state = state$.value;
      const { auth } = state;
      const { accessToken, sessionToken } = auth;

      const { added, removed, itemID } = action.payload;
      return ajax({
        crossDomain: true,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/item_data?_format=json`,
        body: {
          itemID,
          added,
          removed,
          oper: "tags"
        }
      }).pipe(
        map(({ response }) => {
          const { tags } = response;
          return actions.editTagsForItemSuccess(tags, itemID);
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.editTagsForItemError(message, error.status));
        })
      );
    })
  );

export const fetchItemsAfterEditEpic: Epic<
  ActionWithFilters,
  ActionWithFilters,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS)),
    switchMap((action) => {
      const state = state$.value;
      const { auth, language } = state;
      const { accessToken, sessionToken } = auth;

      const { items } = state;
      // setto parametri di ricerca
      const { filters } = state;
      const _filters = setFiltersForSearch(filters);

      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}api/item/list?_format=json`,
        body: {
          lang: language.selectedLanguage?.id,
          page: items.page,
          pageSize: items.itemsPerPage,
          sortBy: "date",
          ..._filters
        }
      }).pipe(
        map((e) => {
          const { results } = e.response;
          const { filters } = results;
          return actions.setFiltersAfter(filters);
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.searchItemsError(message, error.status));
        })
      );
    })
  );
