import React from "react";
import { Route, Redirect, RouteComponentProps } from "react-router-dom";
import { User } from "../../reducers/auth";
import * as H from "history";
import { StaticContext } from "react-router";

interface Props {
  // component: any;
  component: React.JSXElementConstructor<
    RouteComponentProps<any, StaticContext, H.History.PoorMansUnknown>
  >;
  user?: User;
  exact?: boolean;
  path?: string;
  location?: H.Location;
}

const PrivateRoute: React.FC<Props> = (props) => {
  const { component: Component, user, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
