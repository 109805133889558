import * as actions from "../actions";
import { Action, empty } from "../actions/auth";
import { Action as FilterAction } from "../actions/filters";
import { Action as ItemsAction } from "../actions/items";
import { Action as ShareAction } from "../actions/share";
import * as actionTypes from "../constants/actionTypes";

import { CallHistoryMethodAction } from "connected-react-router";
import { Epic } from "redux-observable";
import { concat, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, delay, filter, map, mergeMap } from "rxjs/operators";
import { isOfType } from "typesafe-actions";
import { logTMDataLayer } from "../hooks/useLogTMDataLayer";
import { State } from "../interfaces";
import { User } from "../reducers/auth";
import { encrypt } from "../utils";
import domain from "../utils/domain";

const client_id = process.env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET;

export type ActionWithFilters = Action | FilterAction;
export type ActionWithRedirect = Action | CallHistoryMethodAction<any>;

export const sessionTokenEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType([actionTypes.GET_SESSION_TOKEN, actionTypes.LOGOUT])),
    mergeMap((action) => {
      const { action: nextAction } = action.payload;

      return ajax({
        crossDomain: true,
        url: `${domain}session/token?${new Date().getTime()}`,
        responseType: "text/plain"
      }).pipe(
        mergeMap((e) => {
          let _actions: any[] = [actions.getSessionTokenSuccess(e.response)];

          if (nextAction) _actions.push(nextAction);

          return concat(_actions);
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.getSessionTokenError(message, error.status));
        })
      );
    })
  );

export const newSessionTokenEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType([actionTypes.GET_NEW_SESSION_TOKEN])),
    mergeMap((action) => {
      const { action: nextAction } = action.payload;

      return ajax({
        crossDomain: true,
        url: `${domain}session/token?${new Date().getTime()}`,
        responseType: "text/plain"
      }).pipe(
        mergeMap((e) => {
          let _actions: any[] = [actions.getNewSessionTokenSuccess(e.response)];

          if (nextAction) _actions.push(nextAction);

          return concat(_actions);
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.getNewSessionTokenError(message, error.status));
        })
      );
    })
  );

export const autoLoginEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_SESSION_TOKEN_SUCCESS)),
    mergeMap((action) => {
      const auth = localStorage.getItem("auth");
      if (auth) {
        const _auth = JSON.parse(auth);
        const { access_token, refresh_token } = _auth;
        const localFrom = localStorage.getItem("from");
        const from =
          localFrom === "/" || localFrom === null
            ? "/welcome"
            : localFrom ?? "/welcome";

        return of(actions.loginWithToken(access_token, refresh_token, from));
        // return of(empty());
      } else {
        return of(empty());
      }
    })
  );

export const loginEpic: Epic<ActionWithFilters, ActionWithFilters, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.LOGIN)),
    mergeMap((action) => {
      const { values } = action.payload;
      // console.log("payload ", values);
      const userName = values.userName;
      const password = values.password;
      const state = state$.value;
      const { auth } = state;
      const { sessionToken } = auth;
      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}oauth/token`,
        body: {
          grant_type: "password",
          client_id,
          client_secret,
          username: userName,
          password: password,
          scope: "simple approver manager"
        }
      }).pipe(
        mergeMap(({ response }) => {
          const {
            access_token: accessToken,
            refresh_token: refreshToken,
            expires_in
          } = response;
          const state = state$.value;
          const { auth } = state;
          if (auth.rememberUser) {
            // salvo la response su localStorage
            localStorage.setItem("auth", JSON.stringify(response));
          }
          // recupero il tempo in cui scade il token, che è in secondi,
          // lo trasformo in millisecondi e sottraggo 20 secondi per poterlo refreshare prima che scada
          const timeToRefresh = expires_in * 1000 - 20000;
          // per test
          // const timeToRefresh = 5000;

          return concat(
            [actions.loginSuccess(accessToken, refreshToken, expires_in)],
            of(actions.refreshToken(refreshToken)).pipe(delay(timeToRefresh))
          );
        }),
        catchError((error) => {
          console.log("error ", error);
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.loginError(message, error.status));
        })
      );
    })
  );

export const loginWithTokenEpic: Epic<
  ActionWithFilters,
  ActionWithFilters,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.LOGIN_WITH_TOKEN)),
    mergeMap((action) => {
      const state = state$.value;
      const { auth } = state;
      const { sessionToken } = auth;

      const { refresh_token } = action.payload;
      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}oauth/token`,
        body: {
          grant_type: "refresh_token",
          refresh_token: refresh_token,
          client_id,
          client_secret
        }
      }).pipe(
        mergeMap(({ response }) => {
          const { access_token, refresh_token, expires_in } = response;
          const state = state$.value;
          const { auth } = state;
          if (auth.rememberUser) {
            localStorage.setItem("auth", JSON.stringify(response));
          }
          // recupero il tempo in cui scade il token, che è in secondi,
          // lo trasformo in millisecondi e sottraggo 20 secondi per poterlo refreshare prima che scada
          const timeToRefresh = expires_in * 1000 - 20000;

          return concat(
            [
              actions.refreshTokenSuccess(
                access_token,
                refresh_token,
                expires_in
              ),
              actions.loginSuccess(access_token, refresh_token, expires_in)
            ],
            of(actions.refreshToken(refresh_token)).pipe(delay(timeToRefresh))
          );
        }),
        catchError((error) => {
          console.log("error ", error);
          const message = error.response
            ? error.response.message
            : error.message;

          return concat([
            actions.refreshTokenError(message, error.status),
            actions.loginError(message, error.status),
            actions.logout()
          ]);
        })
      );
    })
  );

export const loginSuccessEpic: Epic<
  ActionWithFilters,
  ActionWithFilters,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.LOGIN_SUCCESS)),
    mergeMap((action) => {
      const { access_token } = action.payload;

      return concat([actions.getUser(access_token), actions.getFilters()]);
    })
  );

export const refreshTokenEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.REFRESH_TOKEN)),
    mergeMap((action) => {
      const state = state$.value;
      const { auth } = state;
      const { sessionToken } = auth;

      const { refresh_token } = action.payload;
      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}oauth/token`,
        body: {
          grant_type: "refresh_token",
          refresh_token: refresh_token,
          client_id,
          client_secret
        }
      }).pipe(
        map(({ response }) => {
          const { access_token, refresh_token, expires_in } = response;
          const state = state$.value;
          const { auth } = state;
          if (auth.rememberUser) {
            localStorage.setItem("auth", JSON.stringify(response));
          }
          return actions.refreshTokenSuccess(
            access_token,
            refresh_token,
            expires_in
          );
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.refreshTokenError(message, error.status));
        })
      );
    })
  );
export const getUserEpic: Epic<
  ActionWithRedirect,
  ActionWithRedirect,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.GET_USER)),
    mergeMap((action) => {
      const { access_token } = action.payload;
      const state = state$.value;
      const { auth } = state;
      const { sessionToken } = auth;

      return ajax({
        crossDomain: true,
        url: `${domain}api/user/data?_format=json&access_token=${access_token}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
          "X-CSRF-Token": sessionToken
        }
      }).pipe(
        mergeMap((e) => {
          const { email, firstname, markets, name, surname, role, portal } =
            e.response;
          const user: User = {
            userName: name,
            firstName: firstname,
            lastName: surname,
            markets,
            email,
            role,
            portal
          };
          // console.log("getUserEpic ", e.response);
          const state = state$.value.auth;
          const redirectToReferrer = state.from !== null && state.from !== "/";
          const from = redirectToReferrer ? state.from : "/welcome";

          logTMDataLayer({
            dataLayer: {
              event: "login",
              userID: user?.email ? encrypt(user?.email as string) : undefined,
              user_type: user?.role?.[0]
            },
            dataLayerName: "dataLayer"
          });
          return of(
            actions.getUserSuccess(user),
            actions.redirectToPage(from as string)
          );
        }),
        catchError((error) => {
          const message = error.response
            ? error.response.message
            : error.message;
          return of(actions.getUserError(message, error.status));
        })
      );
    })
  );

export const logoutEpic: Epic<ActionWithRedirect, ActionWithRedirect, State> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isOfType(actionTypes.LOGOUT)),
    mergeMap((action) => {
      // elimino localStorage
      localStorage.removeItem("auth");

      return of(actions.redirectToPage("/"));
      // const state = state$.value;
      // const { auth } = state;
      // const { accessToken, sessionToken } = auth;

      // return ajax({
      //   crossDomain: true,
      //   method: "POST",
      //   headers: {
      //     Authorization: `Bearer ${accessToken}`,
      //     "X-CSRF-Token": sessionToken
      //   },
      //   url: `${domain}user/logout`
      // })
      //   .mergeMap(({ response }) =>
      //     of(
      //       actions.logoutSuccess(response),
      //       actions.redirectToPage("/")
      //     )
      //   )
      //   .catch(error =>
      //     of(actions.logoutError(error.response.message, error.status))
      //   );
    })
  );

export const recoverPasswordEpic: Epic<
  ActionWithRedirect,
  ActionWithRedirect,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(actionTypes.RECOVER_PASSWORD)),
    mergeMap((action) => {
      const { username, email } = action.payload;
      console.log("email payload ", action.payload);
      const state = state$.value;
      const { auth } = state;
      const { sessionToken } = auth;

      return ajax({
        crossDomain: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": sessionToken
        },
        url: `${domain}user/password?_format=json`,
        body: {
          name: username,
          mail: email
          // name: username
        }
      }).pipe(
        mergeMap(({ response }) =>
          of(actions.recoverPasswordSuccess(), actions.redirectToPage("/"))
        ),
        catchError((error) => {
          console.log("error recovery: ", error);
          let message = error.response ? error.response.message : error.message;

          if (error.status === 400) {
            message =
              "Invalid email, eather check the email you type, or if you are a dealer, contact dealer portal for reset password.";
          }
          return of(actions.recoverPasswordError(message, error.status));
        })
      );
    })
  );

export type ActionWithUnauthorizedUser =
  | Action
  | FilterAction
  | ItemsAction
  | ShareAction;

export const unauthorizedUserEpic: Epic<
  ActionWithUnauthorizedUser,
  ActionWithUnauthorizedUser,
  State
> = (action$, state$) =>
  action$.pipe(
    filter(
      isOfType([
        actionTypes.GET_USER_ERROR,
        actionTypes.GET_FILTERS_ERROR,
        actionTypes.SEARCH_ITEMS_ERROR,
        actionTypes.GET_ITEM_BY_ID_ERROR,
        actionTypes.UNPUBLISH_ITEM_ERROR,
        actionTypes.EDIT_TAGS_FOR_ITEM_ERROR,
        actionTypes.GET_SHARED_LINKS_ERROR
      ])
    ),
    mergeMap((action) => {
      const { status } = action.payload;

      if (status === 401) {
        return of(actions.logout());
      } else {
        return of(empty());
      }
    })
  );
