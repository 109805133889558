import * as actionTypes from "../constants/actionTypes";
import { Entities } from "../interfaces";
import { Item } from "../reducers/items";

export interface SearchItemsAction {
  type: typeof actionTypes.SEARCH_ITEMS;
}
export interface SearchItemsSuccessAction {
  type: typeof actionTypes.SEARCH_ITEMS_SUCCESS;
  payload: {
    entities: Entities<Item>;
    page: number;
    total: number;
    filters: {
      categories: string[];
      item_types: string[];
      markets: string[];
      properties: string[];
      tags: string[];
    };
    userCanEdit: boolean;
    userCanShare: boolean;
  };
}
export interface SearchItemsErrorAction {
  type: typeof actionTypes.SEARCH_ITEMS_ERROR;
  payload: { error: string; status: number };
}
export interface GetNextPageForItemsAction {
  type: typeof actionTypes.GET_NEXT_PAGE_FOR_ITEMS;
  payload: { page: number };
}
export interface ResetPageForItemsAction {
  type: typeof actionTypes.RESET_PAGE_FOR_ITEMS;
}
export interface SelectItemAction {
  type: typeof actionTypes.SELECT_ITEM;
  payload: { item: Item | null | undefined };
}
export interface GetItemByIdAction {
  type: typeof actionTypes.GET_ITEM_BY_ID;
  payload: { itemID: string };
}
export interface GetItemByIdSuccessAction {
  type: typeof actionTypes.GET_ITEM_BY_ID_SUCCESS;
  payload: { item: Item | null | undefined };
}
export interface GetItemByIdErrorAction {
  type: typeof actionTypes.GET_ITEM_BY_ID_ERROR;
  payload: { error: string; status: number };
}
export interface ToggleEditModeAction {
  type: typeof actionTypes.TOGGLE_EDIT_MODE;
}
export interface UnpublishItemAction {
  type: typeof actionTypes.UNPUBLISH_ITEM;
  payload: { itemID: string };
}
export interface UnpublishItemSuccessAction {
  type: typeof actionTypes.UNPUBLISH_ITEM_SUCCESS;
  payload: { itemId: string };
}
export interface UnpublishItemErrorAction {
  type: typeof actionTypes.UNPUBLISH_ITEM_ERROR;
  payload: { error: string; status: number };
}
export interface EditTagsForItemAction {
  type: typeof actionTypes.EDIT_TAGS_FOR_ITEM;
  payload: {
    added: string[];
    removed: string[];
    itemID: string;
  };
}
export interface EditTagsForItemSuccessAction {
  type: typeof actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS;
  payload: { tags: string[]; itemID: string };
}
export interface EditTagsForItemErrorAction {
  type: typeof actionTypes.EDIT_TAGS_FOR_ITEM_ERROR;
  payload: { error: string; status: number };
}
export interface ShowEditModalAction {
  type: typeof actionTypes.SHOW_EDIT_MODAL;
  payload: { visible: boolean; item: Item };
}

export type Action =
  | SearchItemsAction
  | SearchItemsSuccessAction
  | SearchItemsErrorAction
  | GetNextPageForItemsAction
  | ResetPageForItemsAction
  | SelectItemAction
  | GetItemByIdAction
  | GetItemByIdSuccessAction
  | GetItemByIdErrorAction
  | UnpublishItemAction
  | UnpublishItemSuccessAction
  | UnpublishItemErrorAction
  | EditTagsForItemAction
  | EditTagsForItemSuccessAction
  | EditTagsForItemErrorAction
  | ShowEditModalAction
  | ToggleEditModeAction;

export function searchItems(): Action {
  return {
    type: actionTypes.SEARCH_ITEMS
  };
}

export function searchItemsSuccess(result: {
  entities: Entities<Item>;
  page: number;
  total: number;
  filters: {
    categories: string[];
    item_types: string[];
    markets: string[];
    properties: string[];
    tags: string[];
  };
  userCanEdit: boolean;
  userCanShare: boolean;
}): Action {
  return {
    type: actionTypes.SEARCH_ITEMS_SUCCESS,
    payload: result
  };
}

export function searchItemsError(error: string, status: number): Action {
  return {
    type: actionTypes.SEARCH_ITEMS_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function getNextPageForItems(page: number): Action {
  return {
    type: actionTypes.GET_NEXT_PAGE_FOR_ITEMS,
    payload: {
      page
    }
  };
}

export function resetPageForItems(): Action {
  return {
    type: actionTypes.RESET_PAGE_FOR_ITEMS
  };
}

export function selectItem(item: Item | null | undefined): Action {
  return {
    type: actionTypes.SELECT_ITEM,
    payload: {
      item
    }
  };
}

export function getItemById(itemID: string): Action {
  return {
    type: actionTypes.GET_ITEM_BY_ID,
    payload: {
      itemID
    }
  };
}

export function getItemByIdSuccess(item: Item | null | undefined): Action {
  return {
    type: actionTypes.GET_ITEM_BY_ID_SUCCESS,
    payload: {
      item
    }
  };
}

export function getItemByIdError(error: string, status: number): Action {
  return {
    type: actionTypes.GET_ITEM_BY_ID_ERROR,
    payload: {
      error,
      status
    }
  };
}
/****** edit mode ******/

export function toggleEditMode(): Action {
  return {
    type: actionTypes.TOGGLE_EDIT_MODE
  };
}

export function unpublishItem(itemID: string): Action {
  return {
    type: actionTypes.UNPUBLISH_ITEM,
    payload: {
      itemID
    }
  };
}

export function unpublishItemSuccess(itemId: string): Action {
  return {
    type: actionTypes.UNPUBLISH_ITEM_SUCCESS,
    payload: {
      itemId
    }
  };
}

export function unpublishItemError(error: string, status: number): Action {
  return {
    type: actionTypes.UNPUBLISH_ITEM_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function editTagsForItem(
  added: string[],
  removed: string[],
  itemID: string
): Action {
  return {
    type: actionTypes.EDIT_TAGS_FOR_ITEM,
    payload: {
      added,
      removed,
      itemID
    }
  };
}

export function editTagsForItemSuccess(tags: string[], itemID: string): Action {
  return {
    type: actionTypes.EDIT_TAGS_FOR_ITEM_SUCCESS,
    payload: {
      tags,
      itemID
    }
  };
}

export function editTagsForItemError(error: string, status: number): Action {
  return {
    type: actionTypes.EDIT_TAGS_FOR_ITEM_ERROR,
    payload: {
      error,
      status
    }
  };
}

export function showEditModal(visible: boolean, item: Item): Action {
  return {
    type: actionTypes.SHOW_EDIT_MODAL,
    payload: {
      visible,
      item
    }
  };
}
