import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Progress, message } from "antd";
import axios from "axios";
import bytes from "bytes";
import React, { useState } from "react";
import ReactGA from "react-ga";
import { shallowEqual, useSelector } from "react-redux";

import { ClickParam } from "antd/lib/menu";
import { saveAs } from "file-saver";
import { logTMDataLayer } from "../../hooks/useLogTMDataLayer";
import { State } from "../../interfaces";
import { AuthState } from "../../reducers/auth";
import { ItemFile, ItemsState } from "../../reducers/items";

type Direction =
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight"
  | undefined;

type Props = {
  itemID: string;
  files: Array<ItemFile>;
  direction?: Direction;
  label?: string;
  clsName?: string;
};

const DownloadBtn: React.FC<Props> = (props) => {
  const { files, direction, label, clsName, itemID } = props;
  const placement = direction;
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(0);

  const items: ItemsState = useSelector(
    (state: State) => state.items,
    shallowEqual
  );

  const { entities } = items;

  const { byId } = entities;

  const auth: AuthState = useSelector(
    (state: State) => state.auth,
    shallowEqual
  );
  const { accessToken, sessionToken } = auth;

  // console.log("download item >>", byId[itemID]);

  const downloadFile = (e: ClickParam) => {
    const values = e.key.split("~");
    const url = values[0];
    const filename = values[1];

    const selectedItem = byId[itemID];
    console.log("download item >>", byId[itemID]);

    logTMDataLayer({
      dataLayer: {
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_name: selectedItem?.name,
              item_id: selectedItem?.id,
              price: 1,
              quantity: 1,
              item_category: selectedItem?.tags?.[0],
              item_category2: undefined
            }
          ]
        }
      },
      dataLayerName: "dataLayer"
    });
    // console.log("url ", url);
    ReactGA.event({
      category: "Download",
      action: `Download file for item ${itemID}`,
      label: url
    });
    // const urlWithToken = `${url}?access_token=${sessionToken}`;
    // console.log("urlWithToken ", urlWithToken);
    // download(urlWithToken);
    setIsDownloading(true);
    axios({
      method: "get",
      url,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-CSRF-Token": sessionToken
      },
      onDownloadProgress: (progressEvent: any) => {
        // Do whatever you want with the native progress event
        const { loaded, total } = progressEvent;

        const percentage = (100 * loaded) / total;
        const _progress = parseFloat(percentage.toFixed(1));
        setProgress(_progress);
        // console.log("percentage ", percentage);
      }
    })
      .then((data) => {
        // console.log("data ", data.data);
        setIsDownloading(false);
        setProgress(0);
        saveAs(data.data, filename);
      })
      .catch((error) => {
        // console.log("error ", error.message);
        message.error(error.message);
        setIsDownloading(false);
        setProgress(0);
      });
  };

  const menu = (
    <Menu onClick={downloadFile}>
      {files.map((file) => {
        let size = "";
        if (file.hasOwnProperty("filesize")) {
          const sizeInt = parseInt(file.filesize as string, 10);
          size = ` (${bytes(sizeInt)})`;
        }
        return (
          <Menu.Item
            // key={file.url}
            key={`${file.url}~${file.filename}`}
          >
            {file.desc}
            {size} <DownloadOutlined />
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <>
      {isDownloading ? (
        <Progress
          className="progress"
          percent={progress}
          strokeColor="#e68f1a"
          size="small"
        />
      ) : (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement={placement}
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <Button className={`download-btn ${clsName}`}>
            {label}
            <DownloadOutlined style={label === "" ? { marginLeft: 0 } : {}} />
          </Button>
        </Dropdown>
      )}
    </>
  );
};

DownloadBtn.defaultProps = {
  direction: "topCenter" as Direction,
  label: "Download",
  clsName: "btn-grey"
};

export default DownloadBtn;
