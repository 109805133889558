import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { CopyOutlined } from "@ant-design/icons";
import { Checkbox, Modal, Button, message, DatePicker } from "antd";
import bytes from "bytes";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import ReactGA from "react-ga";

import * as actions from "../../../actions";

import { LanguageState } from "../../../reducers/language";
import { ShareState } from "../../../reducers/share";
import { State } from "../../../interfaces";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Item, ItemFile } from "../../../reducers/items";

const CheckboxGroup = Checkbox.Group;

const ShareModal = () => {
  const language: LanguageState = useSelector(
    (state: State) => state.language,
    shallowEqual
  );
  const { labels } = language;

  const share: ShareState = useSelector(
    (state: State) => state.share,
    shallowEqual
  );
  const { item, links, isLoading, showModal } = share;
  const _item = item as Item;

  const [checkedList, setCheckedList] = useState<ItemFile[]>([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const [exp, setExp] = useState(0);
  const [now, setNow] = useState(moment());

  const dispatch = useDispatch();

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setCheckedList([]);
    setIndeterminate(true);
    setCheckAll(false);
    setExp(0);
    setNow(moment());

    dispatch(actions.showShareModal(false, null));
  };

  const onCopy = (text: string, result: boolean) => {
    if (result) {
      message.info(labels.copiedMessage);

      ReactGA.event({
        category: "Download",
        action: `Download file for item ${_item.id}`,
        label: text
      });
    }
  };

  const onChange = (checkedList: any[]) => {
    const { files } = item as Item;
    setCheckedList(checkedList);
    setIndeterminate(!!checkedList.length && checkedList.length < files.length);
    setCheckAll(checkedList.length === files.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const { files } = _item;
    setCheckedList(e.target.checked ? files : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onDateChange = (date: moment.Moment | null, dateString: string) => {
    console.log("date ", date, dateString);
    if (date) {
      console.log("timestamp ", date.valueOf());
      setExp(date.valueOf());
    }
  };

  const onSharedLinksHandler = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const ids = checkedList.map((file) => file.fid);
    // console.log("ids ", ids);
    // console.log("item id = ", item.id);
    dispatch(
      actions.getNewSessionToken(actions.getSharedLinks(_item.id, ids, exp))
    );
  };

  const getFileInfo = (fid: string) => {
    const { files } = _item;
    const _file = files.filter((file) => file.fid === fid);
    return _file.length > 0 ? _file[0].desc : "";
  };

  return (
    <>
      {_item && (
        <Modal
          title={_item.name}
          visible={showModal}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="checkbox-wrapper">
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              {labels.selectAll}
            </Checkbox>
          </div>
          <CheckboxGroup
            className="group-wrapper links"
            onChange={onChange}
            value={checkedList as any}
          >
            {_item.files.map((file) => {
              let size = "";
              if (file.hasOwnProperty("filesize")) {
                const sizeInt = parseInt(file.filesize as string, 10);
                size = ` (${bytes(sizeInt)})`;
              }
              return (
                <div key={file.url} className="checkbox-wrapper">
                  <Checkbox value={file}>
                    {file.desc} {size}
                  </Checkbox>
                </div>
              );
            })}
          </CheckboxGroup>
          <div className="selection-wrapper">
            <span>{labels.validUntil}: </span>
            <DatePicker defaultValue={now} onChange={onDateChange} />
          </div>
          <div className="group-wrapper">
            <Button
              type="primary"
              onClick={onSharedLinksHandler}
              disabled={checkedList.length < 1}
              loading={isLoading}
            >
              {labels.shareLinks}
            </Button>
          </div>
          {links.length > 0 && (
            <>
              <p>{labels.copyLinkHelp}</p>
              <div className="group-wrapper links">
                {links.map((link) => {
                  return (
                    <div key={link.fid} className="btn-wrapper">
                      <CopyToClipboard onCopy={onCopy} text={link.url}>
                        <Button
                          className="btn-copy"
                          type="default"
                          size="small"
                        >
                          <CopyOutlined />
                          {getFileInfo(link.fid)}
                        </Button>
                      </CopyToClipboard>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default ShareModal;
