/*
 * languages
 */
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_ERROR = "GET_LANGUAGES_ERROR";
export const SELECT_LANGUAGE = "SELECT_LANGUAGE";
export const SET_BROWSER_LANGUAGE = "SET_BROWSER_LANGUAGE";
export const GET_LOCALE_SUCCESS = "GET_LOCALE_SUCCESS";
/*
 * auth
 */
export const GET_SESSION_TOKEN = "GET_SESSION_TOKEN";
export const GET_SESSION_TOKEN_ERROR = "GET_SESSION_TOKEN_ERROR";
export const GET_SESSION_TOKEN_SUCCESS = "GET_SESSION_TOKEN_SUCCESS";
export const GET_NEW_SESSION_TOKEN = "GET_NEW_SESSION_TOKEN";
export const GET_NEW_SESSION_TOKEN_ERROR = "GET_NEW_SESSION_TOKEN_ERROR";
export const GET_NEW_SESSION_TOKEN_SUCCESS = "GET_NEW_SESSION_TOKEN_SUCCESS";
export const LOGIN = "LOGIN";
export const LOGIN_WITH_TOKEN = "LOGIN_WITH_TOKEN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const GET_USER = "GET_USER";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";
export const APP_TOKEN_EXPIRED = "APP_TOKEN_EXPIRED";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const EMPTY = "EMPTY";
/*
 * filters
 */
export const GET_FILTERS = "GET_FILTERS";
export const GET_FILTERS_SUCCESS = "GET_FILTERS_SUCCESS";
export const GET_FILTERS_ERROR = "GET_FILTERS_ERROR";
export const SET_FILTERS_AFTER_EDIT = "SET_FILTERS_AFTER_EDIT";
/*
 * searchString
 */
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
/*
 * typologies
 */
export const SET_TYPOLOGY = "SET_TYPOLOGY";
export const TOGGLE_TYPOLOGY_ON_SELECTED_LIST =
  "TOGGLE_TYPOLOGY_ON_SELECTED_LIST";
/*
 * markets
 */
export const SET_MARKET = "SET_MARKET";
export const TOGGLE_MARKET_ON_SELECTED_LIST = "TOGGLE_MARKET_ON_SELECTED_LIST";
/*
 * categories
 */
export const SELECT_CATEGORY = "SELECT_CATEGORY";
/*
 * properties
 */
export const SELECT_PROPERTY = "SELECT_PROPERTY";
/*
 * tags
 */
export const SELECT_TAG = "SELECT_TAG";
/*
 * items
 */
export const SEARCH_ITEMS = "SEARCH_ITEMS";
export const SEARCH_ITEMS_SUCCESS = "SEARCH_ITEMS_SUCCESS";
export const SEARCH_ITEMS_ERROR = "SEARCH_ITEMS_ERROR";
export const GET_NEXT_PAGE_FOR_ITEMS = "GET_NEXT_PAGE_FOR_ITEMS";
export const RESET_PAGE_FOR_ITEMS = "RESET_PAGE_FOR_ITEMS";
export const SELECT_ITEM = "SELECT_ITEM";
export const GET_ITEM_BY_ID = "GET_ITEM_BY_ID";
export const GET_ITEM_BY_ID_SUCCESS = "GET_ITEM_BY_ID_SUCCESS";
export const GET_ITEM_BY_ID_ERROR = "GET_ITEM_BY_ID_ERROR";
export const TOGGLE_EDIT_MODE = "TOGGLE_EDIT_MODE";
export const UNPUBLISH_ITEM = "UNPUBLISH_ITEM";
export const UNPUBLISH_ITEM_SUCCESS = "UNPUBLISH_ITEM_SUCCESS";
export const UNPUBLISH_ITEM_ERROR = "UNPUBLISH_ITEM_ERROR";
export const EDIT_TAGS_FOR_ITEM = "EDIT_TAGS_FOR_ITEM";
export const EDIT_TAGS_FOR_ITEM_SUCCESS = "EDIT_TAGS_FOR_ITEM_SUCCESS";
export const EDIT_TAGS_FOR_ITEM_ERROR = "EDIT_TAGS_FOR_ITEM_ERROR";
export const SHOW_EDIT_MODAL = "SHOW_EDIT_MODAL";
/*
 * Share
 */
export const GET_SHARED_LINKS = "GET_SHARED_LINKS";
export const GET_SHARED_LINKS_SUCCESS = "GET_SHARED_LINKS_SUCCESS";
export const GET_SHARED_LINKS_ERROR = "GET_SHARED_LINKS_ERROR";
export const SHOW_SHARE_MODAL = "SHOW_SHARE_MODAL";

/*
 * Urls
 */
export const CASE_SITE_URL = "http://www.casece.com";
export const BROCHURE_BANK_URL =
  "http://library.caseordersystem.com/Plugins/BrochureLibrary/Login";
export const PRESS_KIT_URL =
  "http://press-kit.casecetools.com/Press-Kit/web_html/Catalog";
export const FACEBOOK_URL =
  "https://www.facebook.com/caseconstructionequipment.italia";
export const TWITTER_URL = "https://twitter.com/CaseCE_EU";
export const YOUTUBE_URL = "http://www.youtube.com/caseatwork";
export const INSTAGRAM_URL = "https://www.instagram.com/casece.eu/";
export const LINKEDIN_URL =
  "https://www.linkedin.com/showcase/case-construction-equipment-europe";
